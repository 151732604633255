import { AudioMetadata } from '@/components/models/AudioMetadata';
import { customEventInit } from '../custom-events-common';
import { PlayerInteractionPayload } from './player-interaction-payload';

export class PlayerRewindEvent extends CustomEvent<PlayerInteractionPayload> {
    public static EventName = 'player-rewind' as const;

    constructor(audioMetadata: AudioMetadata, playlistTitle: string) {
        super(
            PlayerRewindEvent.EventName,
            customEventInit({
                audioMetadata: audioMetadata,
                playlistTitle: playlistTitle
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [PlayerRewindEvent.EventName]: PlayerRewindEvent;
    }
}
