export enum AudioMutations {
    SetPosition = 'setPosition',
    ResetPosition = 'resetPosition',
    SetVolume = 'setVolume',
    SetIsPlaying = 'setIsPlaying',
    SetDuration = 'setDuration',
    SetError = 'setError',
    SetBuffered = 'setBuffered',
    RegisterDisposableEventListener = 'registerDisposableEventListener',
    RegisterPersistentEventListener = 'registerPersistentEventListener',
    SetIsStalled = 'setIsStalled',
    Dispose = 'dispose',
    SetTrackingState = 'setTrackingState'
}
