import { customEventInit } from '../custom-events-common';

interface SearchOpenPayload {
    searchTerm?: string;
}

export class SearchOpenEvent extends CustomEvent<SearchOpenPayload> {
    public static EventName = 'search-open' as const;
    constructor() {
        super(SearchOpenEvent.EventName, customEventInit({}));
    }
}

declare global {
    interface WindowEventMap {
        [SearchOpenEvent.EventName]: SearchOpenEvent;
    }
}
