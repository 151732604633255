import { StoreOptions } from 'vuex';
import SearchApi, { SearchAllResponse } from '@/api/search-api';

export default {
    namespaced: true,
    state: () => ({
        _term: '',
        _searchResult: null,
        _isLoading: false,
        _isError: false,
        _debounceTimeoutId: null
    }),
    getters: {
        term: state => state._term,
        isLoading: state => state._isLoading,
        isError: state => state._isError,
        programs: state => (state._searchResult ? state._searchResult.programs : null),
        episodes: state => (state._searchResult ? state._searchResult.episodes : null),
        channels: state => (state._searchResult ? state._searchResult.channels : null),
        hasSearchResult(state, getters) {
            return (
                (getters.programs && !!getters.programs.hits.length) ||
                (getters.episodes && !!getters.episodes.hits.length) ||
                (getters.channels && !!getters.channels.hits.length)
            );
        }
    },
    actions: {
        setSearchTerm({ commit, dispatch, state }, searchTerm) {
            const delay = 0.35 * 1000;

            commit('_setSearchTerm', searchTerm);

            if (searchTerm.length > 1) {
                commit('_setIsLoading', true);
                if (state._debounceTimeoutId) {
                    clearTimeout(state._debounceTimeoutId);
                }

                state._debounceTimeoutId = window.setTimeout(() => dispatch('requestSearchResults', searchTerm), delay);
            }

            if (searchTerm.length <= 1) {
                dispatch('clearSearchResults');
            }
        },
        requestSearchResults({ commit, dispatch, getters }, searchTerm: string) {
            commit('_setIsError', false);

            SearchApi.getAll({ query: searchTerm, take: 4 })
                .then(result => {
                    commit('_setSearchResult', result);
                })
                .catch(() => {
                    commit('_setIsError', true);
                    dispatch('clearSearchResults');
                })
                .then(() => {
                    commit('_setIsLoading', false);
                });
        },
        clearSearchResults({ commit }) {
            commit('_setSearchResult', null);
        }
    },
    mutations: {
        _setSearchTerm(state, searchTerm: string) {
            state._term = searchTerm.slice(0, 100);
        },
        _setSearchResult(state, searchResult: SearchAllResponse | null) {
            state._searchResult = searchResult;
        },
        _setIsLoading(state, isLoading: boolean) {
            state._isLoading = isLoading;
        },
        _setIsError(state, isError: boolean) {
            state._isError = isError;
        }
    }
} as StoreOptions<SearchState>;

export interface SearchState {
    _term: string;
    _searchResult: SearchAllResponse | null;
    _isLoading: boolean;
    _isError: boolean;
    _debounceTimeoutId: number | null;
}
