export default class GaHeartbeatHelper {
    public lastTracked: number = 0;

    private readonly initialHeartbeat: number = 60;
    private readonly subsequentHeartbeat: number = 300;

    public get nextThreshold(): number {
        if (this.lastTracked < this.subsequentHeartbeat) {
            return Math.floor(this.lastTracked / this.initialHeartbeat) * this.initialHeartbeat + this.initialHeartbeat;
        }

        return this.lastTracked + this.subsequentHeartbeat;
    }

    public threshold(listenedTime: number): number {
        const x = Math.round(listenedTime - this.lastTracked);
        this.lastTracked = listenedTime;
        return x;
    }
}
