import { AudioMetadataItem } from '@/api/responses/AudioResponse';
import { AudioMetadata, AudioType } from '@/components/models';

export function mapScheduleMetadataToAudio(scheduleItem: AudioMetadataItem): AudioMetadata {
    return {
        src: '',
        id: 0,
        episodeTitle: scheduleItem.subtitle,
        programName: '',
        programId: '',
        url: scheduleItem.readmoreurl,
        tracks: [],
        imageUrl: scheduleItem.displayimageurl,
        thumbnailImageUrl: scheduleItem.thumbnailimageurl,
        branding: scheduleItem.channelname,
        date: '',
        channelName: scheduleItem.channelname,
        channelId: scheduleItem.channelid,
        startTime: new Date(scheduleItem.starttime),
        endTime: new Date(scheduleItem.endtime),
        duration: scheduleItem.duration,
        statistics: {
            audioType: AudioType.Undefined,
            audioId: null,
            publicationId: 0,
            backendTrackingMetadata: {
                category: scheduleItem?.statisticsaudio?.category
            }
        },
        isPod: false
    };
}
