import { customEventInit } from './custom-events-common';

type CategoryButtonClickedEventPayload = {
    url: string;
    linkText: string;
};

export class CategoryButtonClickedEvent extends CustomEvent<CategoryButtonClickedEventPayload> {
    public static EventName = 'category-button-clicked' as const;
    constructor(url: string, linkText: string) {
        super(
            CategoryButtonClickedEvent.EventName,
            customEventInit({
                url: url,
                linkText: linkText
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [CategoryButtonClickedEvent.EventName]: CategoryButtonClickedEvent;
    }
}
