import httpClient from '@/common/http-client';
import { AudioQuality } from '@/components/models/AudioQuality';

export default {
    async getStoryCollection(id: string): Promise<StoryCollectionModel> {
        const url = `/backend-for-frontend/story-collection/${id}`;
        const response = await httpClient.get<StoryCollectionModel>(url);
        return response.data;
    },

    async getStory(id: string, params?: StoryParams): Promise<StoryModel> {
        const url = `/backend-for-frontend/story/${id}`;
        const response = await httpClient.get<StoryModel>(url, params);
        return response.data;
    }
};

export interface PlaylistImageModel {
    url: string;
    width: number;
    height: number;
}

export interface StoryCollectionModel {
    id: string;
    title: string;
    publishDateUtc: Date;
    items: StoryModel[];
}

export interface StoryModel {
    id: string;
    url: string;
    title: string;
    header: string;
    description: string;
    publisher: string;
    totalSeconds: number;
    itemCount: number;
    image: PlaylistImageModel;
    items: StorySegmentModel[];
}

export interface StorySegmentModel {
    id: string;
    title: string;
    image: PlaylistImageModel;
    audio: PlayerAudioModel;
}

export interface PlayerAudioModel {
    id: number;
    audioUrl: string;
    duration: number;
    tracking: PlayerAudioTrackingModel;
    publishDate: Date;
}

export interface StoryParams {
    quality: AudioQuality;
}

export interface PlayerAudioTrackingModel {
    key: string;
}
