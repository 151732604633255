export function convertPropertiesToLowercase(params: any): any {
    if (!params || typeof params !== 'object') {
        return params;
    }

    const obj: any = {};

    for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            obj[key.toLowerCase()] = params[key]?.toString().toLowerCase();
        }
    }

    return obj;
}
