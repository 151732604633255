import ListenedTimeHelper from '@/common/ListenedTimeHelper';
import { AudioEvents } from '@/enums';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { reportListenDuration } from '@/tracking/ListenLiveDurationTracker';

enum EventType {
    LISTENING = 'listening',
    START = 'start',
    STOP = 'stop'
}

const UDPDATE_FREQUENCY = 60;

export class ListenLiveDurationTrackerEventListener implements DisposableEventListener, EventListenerObject {
    private hasStarted: boolean = false;
    private paused: boolean = true;
    private audioElement: HTMLAudioElement;
    private audioSrc: string;
    private clientIdHash: string;
    private currentTimePoint: number = 0;
    private listenedTimeHelper: ListenedTimeHelper;
    private latestActionTime: number;

    private getTimeSinceLatestActionMs = (): number => {
        const timeSinceLatestAction = Date.now() - this.latestActionTime;
        return timeSinceLatestAction;
    };

    private getTimeSinceLatestActionSec = (): number => {
        const timeSinceLatestAction = Math.floor((Date.now() - this.latestActionTime) / 1000);
        return timeSinceLatestAction;
    };

    private updateCurrentTimePoint = (change: number): void => {
        this.currentTimePoint = this.currentTimePoint + change;
    };

    private readonly events: AudioEvents[] = [
        AudioEvents.TimeUpdate,
        AudioEvents.Seeking,
        AudioEvents.Ended,
        AudioEvents.Pause,
        AudioEvents.Play,
        AudioEvents.LoadStart
    ];

    constructor(audioElement: HTMLAudioElement, audioSrc: string, startTime: Date, clientIdHash: string) {
        this.audioElement = audioElement;
        this.audioSrc = audioSrc;
        this.currentTimePoint = startTime?.getTime() ?? 0;
        this.clientIdHash = clientIdHash;
        this.latestActionTime = Date.now();
        this.listenedTimeHelper = new ListenedTimeHelper();
        this.events.map(e => this.audioElement.addEventListener(e, this));
    }

    public handleEvent(event: Event): void {
        switch (event.type) {
            case AudioEvents.TimeUpdate:
                this.onTimeUpdate();
                break;
            case AudioEvents.Play:
                this.onPlay();
                break;
            case AudioEvents.Ended:
            case AudioEvents.Pause:
                this.onPause();
                break;
            case AudioEvents.Seeking:
                this.onSeeking();
                break;
            case AudioEvents.Startup:
                this.onStartUp();
                break;
            default:
                break;
        }
    }

    private onStartUp(): void {
        if (!this.hasStarted) {
            this.reportDuration(0, EventType.START);
            this.hasStarted = true;
            this.paused = false;
        }
    }

    private onTimeUpdate(): void {
        this.listenedTimeHelper.currentTime = this.audioElement.currentTime;
        if (this.getTimeSinceLatestActionSec() >= UDPDATE_FREQUENCY && this.hasStarted) {
            this.updateCurrentTimePoint(this.getTimeSinceLatestActionMs());
            this.reportDuration(UDPDATE_FREQUENCY, EventType.LISTENING);
        }
    }

    public dispose(): void {
        this.events.map(e => this.audioElement.removeEventListener(e, this));
    }

    private onPlay(): void {
        this.paused = false;
        this.updateCurrentTimePoint(this.getTimeSinceLatestActionMs());
        this.reportDuration(this.getTimeSinceLatestActionSec(), EventType.START);
    }

    private onSeeking(): void {
        const timeDiff = this.audioElement.currentTime - this.listenedTimeHelper.currentTime;
        this.updateCurrentTimePoint(this.getTimeSinceLatestActionMs());
        if (!this.paused) {
            this.reportDuration(this.getTimeSinceLatestActionSec(), EventType.LISTENING);
        }
        this.updateCurrentTimePoint(Math.floor(timeDiff) * 1000);
        this.latestActionTime = Date.now();
    }

    private onPause(): void {
        this.paused = true;
        this.updateCurrentTimePoint(this.getTimeSinceLatestActionMs());
        this.reportDuration(this.getTimeSinceLatestActionSec(), EventType.STOP);
    }

    private reportDuration(duration: number, eventType: string): void {
        reportListenDuration(this.clientIdHash, duration, eventType, this.audioSrc, new Date(this.currentTimePoint));
        this.latestActionTime = Date.now();
    }
}
