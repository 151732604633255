import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { ExternalLinkClickedTrackingData, ExternalLinkClickedTrackingPayload } from './gtm-events';
import { ExternalLinkClickedEvent } from '@/common/custom-events/external-link-clicked-event';

export class TrackExternalLinkClickedEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleFn = (e: ExternalLinkClickedEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(ExternalLinkClickedEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(ExternalLinkClickedEvent.EventName, this.handleFn);
    }

    handle(event: ExternalLinkClickedEvent): void {
        const payload = event.detail;

        if (!payload) {
            return;
        }

        const eventMetadata: ExternalLinkClickedTrackingPayload = {
            pageType: this.pageMetadataService.pageView?.pageType,
            linkText: payload.title,
            linkUrl: payload.url,
            deepLinkUrl: payload.deepUrl
        };

        const gtmEvent = new ExternalLinkClickedTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
