import { TrackingMetadata } from '@/tracking/track-types';
import { customEventInit } from './custom-events-common';

interface OpenInAppClickedEventPayload {
    trackingMetadata: TrackingMetadata;
}

export class OpenInAppClickedEvent extends CustomEvent<OpenInAppClickedEventPayload> {
    public static EventName = 'open-in-app-clicked' as const;

    constructor(trackingMetadata: TrackingMetadata) {
        super(
            OpenInAppClickedEvent.EventName,
            customEventInit({
                trackingMetadata: trackingMetadata
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [OpenInAppClickedEvent.EventName]: OpenInAppClickedEvent;
    }
}
