import { SearchResultType } from '@/components/models/SearchResultType';
import { customEventInit } from '../custom-events-common';

interface SearchResultClickedPayload {
    searchTerm: string;
    searchResultType: SearchResultType;
}

export class SearchResultClickedEvent extends CustomEvent<SearchResultClickedPayload> {
    public static EventName = 'search-result-clicked' as const;
    constructor(searchTerm: string, searchResultType: SearchResultType) {
        super(
            SearchResultClickedEvent.EventName,
            customEventInit({
                searchTerm: searchTerm,
                searchResultType: searchResultType
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [SearchResultClickedEvent.EventName]: SearchResultClickedEvent;
    }
}
