import { clearCookies } from './cookies';
import { CookieConsentUpdatedEvent } from './custom-events/cookie-consent-updated-event';
import storage, { CookieSettings, UserCookieSettings } from './storage';

const version = '1';

const defaultProductionCookieSettings: CookieSettings = {
    version: version,
    consentSaved: false,
    settingsAllowed: false,
    statisticsAllowed: false,
    thirdPartyAllowed: false
};

const isSettingsAllowed = (): boolean => {
    const settings = getCookieSettings();
    return settings.settingsAllowed ?? false;
};

const isStatisticsAllowed = (): boolean => {
    const settings = getCookieSettings();
    return settings.statisticsAllowed ?? false;
};

const isThirdPartyAllowed = (): boolean => {
    const settings = getCookieSettings();
    return settings.thirdPartyAllowed ?? false;
};

const isConsentSaved = (): boolean => {
    const settings = getCookieSettings();
    return settings.consentSaved ?? false;
};

const getCookieSettings = (): CookieSettings => {
    return storage.cookieSettings ?? defaultProductionCookieSettings;
};

const setCookieSettings = (val: CookieSettings): void => {
    const previousSettings = getCookieSettings();

    if (
        previousSettings.statisticsAllowed !== val.statisticsAllowed ||
        previousSettings.settingsAllowed !== val.settingsAllowed
    ) {
        pushConsentModeToDataLayer(val);
    }

    storage.cookieSettings = val;
    storage.clearStorage(val);

    if (!val.statisticsAllowed) {
        clearCookies();
    }

    const currentSettings = getCookieSettings();
    window.dispatchEvent(new CookieConsentUpdatedEvent(currentSettings, previousSettings));
};

const accept = (userCookieSettings: UserCookieSettings): void => {
    const cookieSettings: CookieSettings = {
        version: version,
        consentSaved: true,
        ...userCookieSettings
    };

    setCookieSettings(cookieSettings);
};

const acceptAll = (): void => {
    const cookieSetting: CookieSettings = {
        version: version,
        consentSaved: true,
        settingsAllowed: true,
        statisticsAllowed: true,
        thirdPartyAllowed: true
    };

    setCookieSettings(cookieSetting);
};

const acceptMandatory = (): void => {
    const cookieSetting: CookieSettings = {
        version: version,
        consentSaved: true,
        settingsAllowed: false,
        statisticsAllowed: false,
        thirdPartyAllowed: false
    };

    setCookieSettings(cookieSetting);
};

const pushConsentModeToDataLayer = (newCookieSettings?: CookieSettings): void => {
    const settings = newCookieSettings ?? getCookieSettings();

    window.dataLayer.push('consent', 'update', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: settings.statisticsAllowed ? 'granted' : 'denied',
        functionality_storage: settings.settingsAllowed ? 'granted' : 'denied'
    });
    window.dataLayer.push({ event: 'consent_updated' });
};

export const consentService = {
    isSettingsAllowed,
    isStatisticsAllowed,
    isThirdPartyAllowed,
    isConsentSaved,
    accept,
    acceptAll,
    acceptMandatory,
    getCookieSettings,
    pushConsentModeToDataLayer
};
