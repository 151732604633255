import { customEventInit } from './custom-events-common';

interface SwipedToLastEventPayload {
    moduleName: string;
}

export class SwipedToLastEvent extends CustomEvent<SwipedToLastEventPayload> {
    public static EventName = 'carousel-swiped-to-last' as const;

    constructor(moduleName: string) {
        super(
            SwipedToLastEvent.EventName,
            customEventInit({
                moduleName: moduleName
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [SwipedToLastEvent.EventName]: SwipedToLastEvent;
    }
}
