export default {
    RECONNECTING: 'Återansluter...',
    LOADING_AUDIO: 'Laddar ljud...',
    STARTING_AUDIO: 'Startar ljud...',
    NO_AUDIO_LOADED: 'Inget ljud laddat',
    BUFFERING: 'Buffrar...',
    PLAYBACK_ABORTED: 'Uppspelningen avbröts',
    METADATA_NOT_FOUND: 'Tyvärr kan vi inte hitta information om ljudet',
    AUDIO_EXPIRED:
        'Här finns inget ljud. Det kan bero på att ljudet innehöll t.ex. upphovsrättsskyddad musik och endast låg kvar i 30 dagar.',
    AUDIO_NOT_FOUND: 'Tyvärr kan vi inte hitta ljudet',
    GENERIC_ERROR: 'Något gick fel vid uppspelning',
    DECODE_ERROR: 'Uppspelning stöds ej av webbläsaren',
    AUDIO_INTERNAL_ERROR: 'Kunde inte hämta ljudet',
    METADATA_INTERNAL_ERROR: 'Kunde inte hämta information om ljudet',
    PLAYBACK_ERROR: 'Ljudet kunde inte spelas',
    INITIALIZE_FAILED: 'Kunde inte starta ljudspelaren (FELKOD: 66)',
    NETWORK_ERROR: 'Ljudet kan inte hämtas, kontrollera din nätverksuppkoppling',
    OTHER_ERROR: 'Okänt fel uppstod i spelaren, pröva att ladda om sidan',
    TIMEOUT_ERROR: 'Det tog för lång tid att hämta ljudet, prova igen'
};
