import { ShowMoreClickedEvent } from '@/common/custom-events/show-more-clicked-event';
import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { ShowMoreClickedTrackingData, ShowMoreClickedTrackingPayload } from './gtm-events';

export class TrackShowMoreClickedEventListener implements DisposableEventListener {
    pageMetadataService: PageMetadataService;
    private handleFn = (e: ShowMoreClickedEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(ShowMoreClickedEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(ShowMoreClickedEvent.EventName, this.handleFn);
    }

    handle(event: ShowMoreClickedEvent): void {
        const payload = event.detail;

        if (!payload?.trackingMetadata) {
            return;
        }

        const trackingMetadata = payload.trackingMetadata;

        const eventMetadata: ShowMoreClickedTrackingPayload = {
            pageType: this.pageMetadataService.pageView?.pageType,
            channelName: trackingMetadata.channelName,
            channelId: trackingMetadata.channelId,
            programName: trackingMetadata.programName,
            programId: trackingMetadata.programId,
            programCategory: trackingMetadata.category
        };

        const gtmEvent: ShowMoreClickedTrackingData = new ShowMoreClickedTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
