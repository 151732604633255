





































    import { Mixins, Component, Prop, Watch } from 'vue-property-decorator';

    import Tooltip from '@/components/player/progress-bar/Tooltip.vue';
    import Handle from '@/components/player/progress-bar/Handle.vue';
    import ProgressLoadingAnimation from '@/components/player/progress-bar/ProgressLoadingAnimation.vue';
    import Progress from '@/components/player/progress-bar/Progress.vue';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import PlayerProgressMixin from '@/components/mixins/PlayerProgressMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';

    import { themeHelper } from '@/common/theme-helper';
    import { secondsToTime } from '@/common/player-time-helper';

    @Component({
        components: {
            Tooltip,
            Handle,
            ProgressLoadingAnimation,
            Progress
        }
    })
    export default class ProgressBarOndemand extends Mixins(PlaybackMixin, MetadataMixin, PlayerProgressMixin, PlayerStateMixin) {
        @Prop(Boolean) alwaysShowHandle: boolean | undefined;

        isHandleActive = false;
        isSeeking = false;
        private seekPosition = 0;

        get showProgressLoadingAnimation(): boolean {
            return this.isLoading || this.isMetadataLoading;
        }

        get audioPositionPercentage(): number {
            return this.isHandleActive || this.isSeeking ? this.activeHandlePositionPercentage : this.streamPositionPercentage;
        }

        get activeHandlePositionPercentage(): number {
            const handlePosition = (this.seekPosition / this.duration) * 100;
            if (handlePosition > 100) {
                return 100;
            }
            if (handlePosition < 0) {
                return 0;
            }
            return handlePosition;
        }

        get streamPositionPercentage(): number {
            return (this.streamPosition / this.duration) * 100;
        }

        get bufferPercentage(): number {
            const buffer = (this.buffer / this.duration) * 100;
            if (buffer > 100) {
                return 100;
            }
            return buffer;
        }

        get seekPositionFormatted(): string {
            return secondsToTime(this.seekPosition);
        }

        get brandingBackgroundColor(): string {
            return themeHelper.getBgColorClass(this.currentAudio.branding);
        }

        get bufferCss(): string {
            return `width: ${this.bufferPercentage}%`;
        }

        onTouchClick(event: TouchEvent): void {
            this.setSeekPosition(event.touches[0].clientX);
            this.isHandleActive = true;
        }

        onMouseClick(event: MouseEvent): void {
            this.setSeekPosition(event.pageX);
            this.isHandleActive = true;
        }

        onTouchScrubbing(event: TouchEvent): void {
            if (this.isSeeking) {
                return;
            }
            this.setSeekPosition(event.touches[0].clientX);
            this.isHandleActive = true;
        }

        onMouseScrubbing(event: MouseEvent): void {
            if (event.buttons === 0) {
                this.isHandleActive = false;
            }
            if (this.isSeeking) {
                return;
            }
            this.setSeekPosition(event.pageX);
            if (event.buttons > 0) {
                this.isHandleActive = true;
            }
        }

        setSeekPosition(xValue: number): void {
            const el = this.$refs.progressBar as HTMLElement;
            if (!el) {
                return;
            }

            let seekPosition = ((xValue - el.getBoundingClientRect().left) / el.offsetWidth) * this.duration;

            if (seekPosition < 0) {
                seekPosition = 0;
            }
            if (seekPosition > this.duration) {
                seekPosition = this.duration;
            }

            this.seekPosition = seekPosition;
        }

        onScrubbingEnd(): void {
            if (!this.isHandleActive) {
                return;
            }

            this.isHandleActive = false;
            this.isSeeking = true;
            this.seek(this.seekPosition);
        }

        @Watch('streamPosition')
        onStreamPositionChange(): void {
            if (this.isSeeking) {
                this.isSeeking = false;
            }
        }
    }
