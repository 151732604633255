import { consentService } from '@/common/consent-service';
import httpClient from '@/common/http-client';
import siteSettings from '@/common/site-settings';
import { AxiosError } from 'axios';

function trimUrl(trackUrl: string, statisticsEventApiUrl: string): string {
    const url = statisticsEventApiUrl.endsWith('/') && trackUrl.startsWith('/') ? trackUrl.substring(1) : trackUrl;
    return statisticsEventApiUrl + url;
}

function getCurrentPath(): string | null {
    if (window) {
        return window.location.host + location.pathname;
    }
    return null;
}

export function reportListenDuration(
    audioId: number,
    isEmbedPlayer: boolean,
    elapsedStartSeconds: number,
    elapsedStopSeconds: number,
    useBeacon: boolean,
    eventType: string
): void {
    if (!consentService.isStatisticsAllowed()) {
        return;
    }

    const statisticsEventApiUrl = siteSettings.statisticsEventApiUrl;
    if (!statisticsEventApiUrl) {
        return;
    }

    const platform = isEmbedPlayer ? 'embed' : 'site';
    const params = [];
    params.push('eventType=' + eventType);
    params.push('audioId=' + audioId);
    params.push('start=' + Math.round(elapsedStartSeconds));
    params.push('stop=' + Math.round(elapsedStopSeconds));
    params.push('app=spelare');
    params.push('platform=' + platform);
    params.push('referrer=' + getCurrentPath());

    const trackUrl = '/v1/listenduration?' + params.join('&');
    const url = trimUrl(trackUrl, statisticsEventApiUrl);

    if (useBeacon && navigator.sendBeacon) {
        navigator.sendBeacon(url);
    } else {
        httpClient
            .get(url)
            .then(() => {
                return;
            })
            .catch((error: AxiosError) => {
                if (error.code === '400') {
                    return;
                }
                throw error;
            });
    }
}
