






    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiOverline extends Vue {
        @Prop(Boolean) readonly gray: boolean | undefined;
        @Prop(Boolean) readonly white: boolean | undefined;
        @Prop(Boolean) readonly small: boolean | undefined;
        @Prop(Boolean) readonly regular: boolean | undefined;
    }
