import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import {
    GtmTrackingData,
    SearchClickedArticleTrackingData,
    SearchClickedEpisodeTrackingData,
    SearchClickedLatestResultTrackingData,
    SearchClickedProgramTrackingData,
    SearchOpenTrackingData,
    SearchTrackingPayload
} from './gtm-events';
import { PageMetadataService } from '@/common/page-metadata-service';
import { SearchOpenEvent } from '@/common/custom-events/search/search-open-event';
import { SearchResultClickedEvent } from '@/common/custom-events/search/search-result-clicked-event';
import { SearchResultType } from '@/components/models/SearchResultType';

export type SearchEvent = SearchOpenEvent | SearchResultClickedEvent;

export class TrackSearchEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleFn = (e: SearchEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(SearchOpenEvent.EventName, this.handleFn);
        window.addEventListener(SearchResultClickedEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(SearchOpenEvent.EventName, this.handleFn);
        window.removeEventListener(SearchResultClickedEvent.EventName, this.handleFn);
    }

    handle(event: SearchEvent): void {
        const eventData = event.detail;

        if (!eventData) {
            return;
        }

        const eventMetadata: SearchTrackingPayload = {
            pageType: this.pageMetadataService.pageView?.pageType,
            searchTerm: eventData.searchTerm
        };

        let gtmTrackingData: GtmTrackingData<SearchTrackingPayload> | null = null;

        if (event instanceof SearchOpenEvent) {
            gtmTrackingData = new SearchOpenTrackingData(eventMetadata);
        } else if (event instanceof SearchResultClickedEvent) {
            switch (event.detail.searchResultType) {
                case SearchResultType.LatestSearchResults:
                    gtmTrackingData = new SearchClickedLatestResultTrackingData(eventMetadata);
                    break;
                case SearchResultType.Episodes:
                    gtmTrackingData = new SearchClickedEpisodeTrackingData(eventMetadata);
                    break;
                case SearchResultType.Programs:
                    gtmTrackingData = new SearchClickedProgramTrackingData(eventMetadata);
                    break;
                case SearchResultType.Articles:
                    gtmTrackingData = new SearchClickedArticleTrackingData(eventMetadata);
                    break;
                default:
                    return;
            }
        }

        if (gtmTrackingData != null) {
            googleTagManager.track(gtmTrackingData);
        }
    }
}
