import { TrackingMetadata } from '@/tracking/track-types';
import { customEventInit } from './custom-events-common';

interface ShowMoreClickedEventPayload {
    trackingMetadata: TrackingMetadata;
}

export class ShowMoreClickedEvent extends CustomEvent<ShowMoreClickedEventPayload> {
    public static EventName = 'show-more-clicked' as const;

    constructor(trackingMetadata: TrackingMetadata) {
        super(
            ShowMoreClickedEvent.EventName,
            customEventInit({
                trackingMetadata: trackingMetadata
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [ShowMoreClickedEvent.EventName]: ShowMoreClickedEvent;
    }
}
