import { StoreOptions } from 'vuex';
import trafficApi, { LocalTrafficResponse, LocalTrafficItem } from '@/api/traffic-api';

export default {
    namespaced: true,
    state: {
        data: null
    },
    getters: {
        localName(state): string | null {
            return state.data ? state.data.localName : null;
        },
        items(state): LocalTrafficItem[] {
            return state.data ? state.data.items : [];
        },
        url(state): string {
            return state.data ? state.data.url : '';
        }
    },
    actions: {
        load(context, localId: number) {
            trafficApi
                .getLocalTraffic({
                    localId
                })
                .then(result => {
                    context.commit('setData', result.data);
                });
        }
    },
    mutations: {
        setData(state, data: LocalTrafficResponse) {
            state.data = data;
        }
    }
} as StoreOptions<State>;

type State = {
    data: LocalTrafficResponse | null;
};
