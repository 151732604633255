import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { ScheduleClickedEvent } from '@/common/custom-events/schedule-clicked-event';
import { ScheduleClickedTrackingData, ScheduleClickedTrackingPayload } from './gtm-events';

export class TrackScheduleClickedEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleFn = (e: ScheduleClickedEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(ScheduleClickedEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(ScheduleClickedEvent.EventName, this.handleFn);
    }

    handle(event: ScheduleClickedEvent): void {
        const payload = event.detail;

        if (!payload) {
            return;
        }

        const eventMetadata: ScheduleClickedTrackingPayload = {
            channelName: payload.channelName,
            channelId: payload.channelId,
            pageType: this.pageMetadataService.pageView?.pageType
        };

        const gtmEvent = new ScheduleClickedTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
