import { AudioEvents } from '@/enums';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { throttledErrorLogger } from '@/logging/ThrottledErrorLogger';

export class PlayerErrorEventListener implements DisposableEventListener, EventListenerObject {
    private audioElement: HTMLAudioElement;
    private isEmbed: boolean;

    private readonly events: AudioEvents[] = [AudioEvents.Error];

    constructor(audioElement: HTMLAudioElement, isEmbed: boolean) {
        this.audioElement = audioElement;
        this.isEmbed = isEmbed;

        this.events.map(e => this.audioElement.addEventListener(e, this));
    }

    public handleEvent(event: ErrorEvent): void {
        if (!this.audioElement.error?.code) {
            return;
        }

        throttledErrorLogger.logError({
            message: 'Ondemand player error',
            application: this.isEmbed ? 'embedspelare' : 'webbradio',
            url: this.audioElement.src,
            error: {
                message: event.message ?? undefined,
                name: this.audioElement.error?.code.toString()
            },
            data: {
                error: event.error,
                filename: event.filename,
                currentTime: this.audioElement.currentTime,
                duration: this.audioElement.duration,
                networkState: this.audioElement.networkState
            }
        });
    }

    public dispose(): void {
        this.events.map(e => this.audioElement.removeEventListener(e, this));
    }
}
