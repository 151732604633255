import { customEventInit } from './custom-events-common';

type PageRenderedEventPayload = {
    document: Document;
};

export class PageRenderedEvent extends CustomEvent<PageRenderedEventPayload> {
    public static EventName = 'page-rendered' as const;

    constructor(document: Document) {
        super(
            PageRenderedEvent.EventName,
            customEventInit({
                document: document
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [PageRenderedEvent.EventName]: PageRenderedEvent;
    }
}
