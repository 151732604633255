import ListenedTimeHelper from '@/common/ListenedTimeHelper';
import { AudioMetadata } from '@/components/models/';
import { AudioEvents } from '@/enums';
import { TrackingState } from '@/store/audio/TrackingState';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { AudioPlayerAction } from '@/tracking/CategorizedAudioPlayerAction';
import GaHeartbeatHelper from '@/tracking/GaHeartbeatHelper';
import storage from '@/common/storage';
import { logger } from '@/api/log-api';
import { TrackingMetadata } from './track-types';
import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import {
    PlayerAudioStartData,
    PlayerCompletedListeningData,
    PlayerListenedTimeData,
    PlayerSuccessfulAudioStart10Data,
    PlayerSuccessfulAudioStart30Data,
    PlayerSuccessfulAudioStart60Data,
    PlayerSuccessfulAudioStartData,
    PlayerListeningTrackingPayload,
    PlayerListenedTimeTrackingPayload
} from '@/tracking/gtm/gtm-player-events';
import { GtmTrackingData } from '@/tracking/gtm/gtm-events';
import { gtmPlayerTracker } from './gtm/gtm-player-tracker';
export class PlayerTrackEventListener implements DisposableEventListener, EventListenerObject {
    private audioElement: HTMLAudioElement;

    private metadata: AudioMetadata;
    private trackingState: TrackingState;
    private listenedTimeHelper: ListenedTimeHelper;
    private gaHeartbeatHelper: GaHeartbeatHelper;

    private hasTrackedAudioStart: boolean = false;
    private hasTracked5Seconds: boolean = false;
    private hasTracked10Seconds: boolean = false;
    private hasTracked30Seconds: boolean = false;
    private hasTracked60Seconds: boolean = false;
    private hasTracked80Percent: boolean = false;

    private readonly events: AudioEvents[] = [
        AudioEvents.TimeUpdate,
        AudioEvents.Seeking,
        AudioEvents.Ended,
        AudioEvents.Pause,
        AudioEvents.LoadStart,
        AudioEvents.Play
    ];

    constructor(audioElement: HTMLAudioElement, audioMetadata: AudioMetadata, trackingState: TrackingState) {
        this.audioElement = audioElement;

        this.metadata = audioMetadata;
        this.trackingState = trackingState;

        this.listenedTimeHelper = new ListenedTimeHelper();
        this.gaHeartbeatHelper = new GaHeartbeatHelper();

        this.events.map(e => this.audioElement.addEventListener(e, this));
    }

    public handleEvent(event: Event): void {
        switch (event.type) {
            case AudioEvents.TimeUpdate:
                this.onTimeUpdate();
                break;
            case AudioEvents.Seeking:
                this.onSeeking();
                break;
            case AudioEvents.Ended:
            case AudioEvents.Pause:
                this.heartbeat();
                break;
            case AudioEvents.LoadStart:
                this.heartbeat();
                break;
            case AudioEvents.Play:
                this.onPlay();
                break;
            default:
                break;
        }
    }

    public dispose(): void {
        this.heartbeat();
        this.events.map(e => this.audioElement.removeEventListener(e, this));
    }

    private onSeeking(): void {
        this.listenedTimeHelper.onSeek(this.audioElement.currentTime);
    }

    private onPlay(): void {
        if (!this.hasTrackedAudioStart) {
            this.sendData(AudioPlayerAction.AudioStart);
            this.hasTrackedAudioStart = true;
        }
    }

    private onTimeUpdate(): void {
        this.listenedTimeHelper.currentTime = this.audioElement.currentTime;

        if (!this.hasTracked5Seconds && this.listenedTimeHelper.listenedTime > Thresholds.FiveSeconds) {
            this.sendData(AudioPlayerAction.Listened5Seconds);
            this.hasTracked5Seconds = true;
        }

        if (!this.hasTracked10Seconds && this.listenedTimeHelper.listenedTime > Thresholds.TenSeconds) {
            this.sendData(AudioPlayerAction.Listened10Seconds);
            this.hasTracked10Seconds = true;
        }

        if (!this.hasTracked30Seconds && this.listenedTimeHelper.listenedTime > Thresholds.ThirtySeconds) {
            this.sendData(AudioPlayerAction.Listened30Seconds);
            this.hasTracked30Seconds = true;
        }

        if (!this.hasTracked60Seconds && this.listenedTimeHelper.listenedTime > Thresholds.SixtySeconds) {
            this.sendData(AudioPlayerAction.Listened60Seconds);
            this.hasTracked60Seconds = true;
        }

        if (!this.hasTracked80Percent && this.listenedTimeHelper.listenedTime > this.audioElement.duration * 0.8) {
            this.sendData(AudioPlayerAction.ListenedThrough);
            this.hasTracked80Percent = true;
        }

        if (
            this.listenedTimeHelper.listenedTime > this.gaHeartbeatHelper.nextThreshold &&
            this.gaHeartbeatHelper.lastTracked < this.gaHeartbeatHelper.nextThreshold
        ) {
            this.heartbeat();
        }
    }

    private heartbeat(): void {
        const sevenHours = 25200;
        const prevLastTracked = this.gaHeartbeatHelper.lastTracked;
        const trackValue = this.gaHeartbeatHelper.threshold(this.listenedTimeHelper.listenedTime);

        if (trackValue < 1) {
            return;
        }

        if (trackValue > sevenHours) {
            logger.logDebug({
                message: 'Heartbeat too large',
                source: 'GaTrackerEventListener, ondemand',
                url: this.audioElement.src,
                data: {
                    trackValue,
                    gaHeartbeatHelper: {
                        prevLastTracked,
                        nextThreshold: this.gaHeartbeatHelper.nextThreshold
                    },
                    listenedTimeHelper: {
                        currentTime: this.listenedTimeHelper.currentTime,
                        listenedTime: this.listenedTimeHelper.listenedTime
                    },
                    audioPosition: storage.audioPosition,
                    playerLastTracked: storage.lastTracked,
                    accumulatedListenedTime: storage.accumulatedListenedTime,
                    audioElCurrentTime: this.audioElement.currentTime,
                    audioElSrc: this.audioElement.src
                }
            });

            return;
        }

        this.sendData(AudioPlayerAction.Listening, trackValue);
    }

    private sendData(trackType: AudioPlayerAction, value?: number): void {
        const trackingMetadata = this.createTrackingMetadata(this.trackingState, this.metadata);

        const playerMetadata = this.createGoogleTagManagerMetadata(trackingMetadata, trackType, value);
        googleTagManager.track(playerMetadata);
    }

    private createTrackingMetadata(trackingState: TrackingState, audioMetadata?: AudioMetadata): TrackingMetadata {
        const metadata: TrackingMetadata = (audioMetadata?.statistics.backendTrackingMetadata as TrackingMetadata) || {};

        metadata.autoplay = trackingState.startedFromAutoplay ? 'true' : 'false';
        metadata.startedFromStarttime = trackingState.startedFromStarttime ? 'true' : 'false';

        if (trackingState.playlistTitle) {
            metadata.collectionName = trackingState.playlistTitle;
        }

        metadata.autoplayEnabled = storage.disableAutoplay ? 'false' : 'true';

        return metadata;
    }

    private createGoogleTagManagerMetadata(
        trackingMetadata: TrackingMetadata,
        trackType: AudioPlayerAction,
        listenedTime?: number
    ): GtmTrackingData<PlayerListeningTrackingPayload> {
        const playerTrackingPayload = gtmPlayerTracker.createGtmPayload(this.metadata, trackingMetadata.collectionName);

        const playerListeningPayload: PlayerListeningTrackingPayload = {
            ...playerTrackingPayload,
            autoplay: trackingMetadata.autoplay
        };

        if (trackType == AudioPlayerAction.AudioStart) {
            return new PlayerAudioStartData(playerListeningPayload);
        }

        if (trackType == AudioPlayerAction.Listened5Seconds) {
            return new PlayerSuccessfulAudioStartData(playerListeningPayload);
        }

        if (trackType == AudioPlayerAction.Listened10Seconds) {
            return new PlayerSuccessfulAudioStart10Data(playerListeningPayload);
        }

        if (trackType == AudioPlayerAction.Listened30Seconds) {
            return new PlayerSuccessfulAudioStart30Data(playerListeningPayload);
        }

        if (trackType == AudioPlayerAction.Listened60Seconds) {
            return new PlayerSuccessfulAudioStart60Data(playerListeningPayload);
        }

        if (trackType == AudioPlayerAction.Listening) {
            const payload: PlayerListenedTimeTrackingPayload = {
                ...playerListeningPayload,
                listenedTime: listenedTime ?? 0
            };

            return new PlayerListenedTimeData(payload);
        }

        if (trackType == AudioPlayerAction.ListenedThrough) {
            return new PlayerCompletedListeningData(playerListeningPayload);
        }

        return new PlayerAudioStartData(playerListeningPayload);
    }
}

enum Thresholds {
    FiveSeconds = 5,
    TenSeconds = 10,
    ThirtySeconds = 30,
    SixtySeconds = 60
}
