import { logger } from '@/api/log-api';
import { LogData } from '@/logging/LogTypes';

class ThrottledErrorLogger {
    private lastError?: string | undefined;
    private lastErrorSent?: Date | undefined;
    private numReportedErrors: number = 0;

    public logError(data: LogData): void {
        if (this.shouldReportError(data.message)) {
            this.numReportedErrors += 1;
            this.lastError = data.message;
            this.lastErrorSent = new Date();
            logger.logError(data);
        }
    }

    private shouldReportError(errorMessage: string): boolean {
        if (!this.lastErrorSent) {
            return true;
        }
        if (this.lastError === errorMessage) {
            return false;
        }

        const diff = new Date().getTime() - this.lastErrorSent.getTime();
        const threshold = this.getThresholdLevel();

        return diff >= threshold;
    }

    private getThresholdLevel(): number {
        // Increase threshold for each 10 errors by 15 seconds
        return Math.ceil(this.numReportedErrors / 10) * 15 * 1000;
    }
}

export const throttledErrorLogger = new ThrottledErrorLogger();
