import { customEventInit, NoPayload } from '@/common/custom-events/custom-events-common';

export class MenuOpenEvent extends CustomEvent<NoPayload> {
    public static EventName = 'menu-open' as const;
    constructor() {
        super(MenuOpenEvent.EventName, customEventInit());
    }
}

declare global {
    interface WindowEventMap {
        [MenuOpenEvent.EventName]: MenuOpenEvent;
    }
}
