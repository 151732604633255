export default {
    get apiUrl(): string {
        return getSetting('api-url', '//api.sr.se/');
    },
    get embedUrl(): string {
        return getSetting('embed-url', '//embed.sr.se/');
    },
    get statisticsEventApiUrl(): string {
        return getSetting('statistics-event-api-url', '//statistics-event-api-fe.sr.se/');
    },
    get svgSpriteName(): string {
        if (process.env.VUE_ENV === 'server') {
            return process.env.SVG_SPRITE_PATH ?? '';
        }

        return getSetting('svg-sprite-name', '');
    },
    get isHlsEnabled(): boolean {
        return getBoolSetting('is-hls-enabled', 'true');
    },
    get frontendLoggingEnabled(): boolean {
        return getBoolSetting('frontend-logging-enabled', 'false');
    },
    get version(): string {
        return getSetting('version', '1.0.0');
    },
    get googleTagManagerId(): string {
        return getSetting('google-tag-manager-id', '');
    },
    get googleTagManagerEnabled(): boolean {
        return getBoolSetting('google-tag-manager-enabled', 'false');
    },
    get googleAdsTagId(): string {
        return getSetting('google-ads-tag-id', '');
    },
    get googleAdsTagEnabled(): boolean {
        return getBoolSetting('google-ads-tag-enabled', 'false');
    }
};

function getSetting(elementId: string, defaultValue: string): string {
    const element = document.getElementById(elementId) as HTMLInputElement;
    return element ? element.value : defaultValue;
}

function getBoolSetting(elementId: string, defaultValue: string): boolean {
    const value = getSetting(elementId, defaultValue);
    return value === 'true';
}
