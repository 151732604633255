import { PlayerContext } from '@/enums/PlayerContext';
import { PlayerMode } from '@/enums/PlayerMode';
import { throttledErrorLogger } from '@/logging/ThrottledErrorLogger';

export const playerLogger = {
    logIcecastError: (audioUrl: string, errorMessage: string): void => {
        logError(audioUrl, PlayerMode.Icecast, errorMessage);
    },
    logHlsError: (audioUrl: string, errorMessage: string): void => {
        logError(audioUrl, PlayerMode.Hls, errorMessage);
    }
};

function logError(audioUrl: string, playerType: PlayerMode, errorMessage: string): void {
    const trackContext = window.srEmbed ? PlayerContext.Embed : PlayerContext.Site;

    throttledErrorLogger.logError({
        message: `${playerType}: ${errorMessage}`,
        application: trackContext,
        url: audioUrl
    });
}
