import { AudioMetadata } from '@/components/models/AudioMetadata';
import { customEventInit } from '../custom-events-common';
import { PlayerInteractionPayload } from './player-interaction-payload';

export class PlayerFastforwardEvent extends CustomEvent<PlayerInteractionPayload> {
    public static EventName = 'player-fastforward' as const;

    constructor(audioMetadata: AudioMetadata, playlistTitle: string) {
        super(
            PlayerFastforwardEvent.EventName,
            customEventInit({
                audioMetadata: audioMetadata,
                playlistTitle: playlistTitle
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [PlayerFastforwardEvent.EventName]: PlayerFastforwardEvent;
    }
}
