import httpClient from '@/common/http-client';

export default {
    getLocalNews(params: LocalNewsRequest) {
        const url = '/ajax/localnews';
        return httpClient.get<LocalNewsResult>(url, params);
    }
};

type LocalNewsRequest = {
    localId: number;
};

type LocalNewsResultItem = {
    publicationId: number;
    time: string;
    title: string;
    url: string;
    audio: LocalNewsResultAudio;
    imageUrl: string;
    languageCode: string;
};

type LocalNewsResultAudio = {
    id: number;
    type: string;
    duration: string;
    durationAbbr: string;
};

export type LocalNewsResult = {
    items: LocalNewsResultItem[];
};
