import { customEventInit } from '@/common/custom-events/custom-events-common';

export type SelectAdditionalChannelEventSource = 'global-meny' | 'karusell';

type SelectAdditionalChannelPayload = {
    eventSource: SelectAdditionalChannelEventSource;
};
export class SelectAdditionalChannelEvent extends CustomEvent<SelectAdditionalChannelPayload> {
    public static EventName = 'select-additional-channel' as const;

    constructor(eventSource: SelectAdditionalChannelEventSource) {
        super(
            SelectAdditionalChannelEvent.EventName,
            customEventInit({
                eventSource: eventSource
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [SelectAdditionalChannelEvent.EventName]: SelectAdditionalChannelEvent;
    }
}
