import { LegacyLivePlayerGetter } from '@/enums/LegacyLivePlayerGetter';
import { PlayerMessages } from '@/enums/PlayerMessages';
import { AudioGetters, MetadataGetters } from '@/store';
import { PlayerState } from '@/store/PlayerState';
import { Component, Vue } from 'vue-property-decorator';
import { legacyPlayerStateMapper } from './mappers/legacyPlayerStateMapper';
import { RootGetters } from '@/store/RootGetters';

@Component
export default class PlayerStateMixin extends Vue {
    private get legacyState(): PlayerState {
        return legacyPlayerStateMapper(this.$store.getters[LegacyLivePlayerGetter.state]);
    }

    private get errorCode(): number {
        const error = this.$store.getters[AudioGetters.Error] as MediaError;
        return error ? error.code : 0;
    }

    protected get isPlaying(): boolean {
        if (this.$store.getters[RootGetters.isOndemandPlayerMode]) {
            return this.$store.getters[AudioGetters.IsPlaying];
        }

        return (
            this.legacyState === PlayerState.Playing ||
            this.legacyState === PlayerState.Loading ||
            this.legacyState === PlayerState.Reconnecting
        );
    }

    protected get isLoading(): boolean {
        if (this.$store.getters[RootGetters.isOndemandPlayerMode]) {
            return this.$store.getters[AudioGetters.IsStalled];
        }
        return this.legacyState === PlayerState.Loading || this.legacyState === PlayerState.Reconnecting;
    }

    protected get isHlsLoaded(): boolean {
        return !!this.$store.getters[LegacyLivePlayerGetter.hlsStreamPosition];
    }

    protected get isFatal(): boolean {
        if (this.$store.getters[RootGetters.isOndemandPlayerMode]) {
            return !!this.$store.getters[AudioGetters.Error];
        }
        return this.legacyState === PlayerState.Error;
    }

    protected get errorMessage(): string {
        if (this.$store.getters[RootGetters.isOndemandPlayerMode]) {
            if (this.isFatal && this.errorCode === 1) {
                return PlayerMessages.PlaybackAbortedError;
            }
            if (this.isFatal && this.errorCode === 2) {
                return PlayerMessages.PlaybackNetworkError;
            }
            if (this.isFatal && this.errorCode === 3) {
                return PlayerMessages.PlaybackCorruptFileError;
            }
            if (this.isFatal && this.errorCode === 4) {
                return PlayerMessages.PlaybackNotSupportedError;
            }
        }
        return this.$store.getters[LegacyLivePlayerGetter.StatusMessage];
    }

    protected get playerMessage(): string {
        return this.$store.getters[LegacyLivePlayerGetter.PlayerMessage];
    }

    $store: any;
}
