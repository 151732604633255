
function getChannelSong(songs, userTime) {
    if (!songs || !songs.length)
        return null;
    var now = userTime || new Date();
    return songs.filter(function (song) {
        return new Date(song.starttime) < now && now < new Date(song.endtime);
    })[0];
}

function getItemByTime(scheduleItems, time) {
    if (!scheduleItems || !scheduleItems.length)
        return {};

    if (!time)
        time = new Date();
    var item = scheduleItems.filter(function (item) {
        return new Date(item.starttime) <= time && time < new Date(item.endtime);
    })[0];

    return item || {};
}

function getPreviousItemByTime(scheduleItems, time) {
    if (!scheduleItems || !scheduleItems.length)
        return {};

    if (!time)
        time = new Date();

    var items = scheduleItems.filter(x => new Date(x.endtime) < time);
    var index = items.length && items.length > 1 ? items.length - 1 : 0;

    return items[index] || {};
}

function createChannelSchedule(scheduleItems, currentItem) {
    if (!scheduleItems || !scheduleItems.length)
        return [];
    var now = new Date();
    var currentItemIndex = scheduleItems.indexOf(currentItem);

    var itemsToShow = 5;
    var itemsBeforeCurrent = 2;
    var startIndex = currentItemIndex >= itemsBeforeCurrent ? currentItemIndex - itemsBeforeCurrent : 0;
    var endIndex = startIndex + itemsToShow;

    return {
        items: scheduleItems
            .slice(startIndex, endIndex)
            .map(function (item) {
                return {
                    url: item.readmoreurl,
                    hasUrl: item.readmoreurl !== null && item.readmoreurl.length > 0,
                    title: item.subtitle,
                    starttime: item.starttimeString,
                    isCurrent: item === currentItem,
                    isCurrentLive: item === getItemByTime(scheduleItems, now),
                    catchUpStartTime: item.starttime,
                    isSeekable: item.starttime < now && (now - item.starttime) / 3600000 < 3
                };
            }),
        title: scheduleItems[0].scheduletitle,
        scheduleurl: scheduleItems[0].scheduleurl
    };
}

export default {
    createChannelSchedule: createChannelSchedule,
    getItemByTime: getItemByTime,
    getPreviousItemByTime: getPreviousItemByTime,
    getChannelSong: getChannelSong
};
