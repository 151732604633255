import { GtmTrackingData } from '@/tracking/gtm/gtm-events';

export interface PlayerTrackingPayload {
    audioID?: string;
    audioType?: string;
    channelName?: string;
    channelID?: string;
    programName?: string;
    programID?: string;
    audioName?: string;
    publicationID?: number;
    programCategory?: string;
    audioLength?: number;
    playlist?: string;
    daysFromPublish?: string;
    publishDate?: string;
}
export interface PlayerListeningTrackingPayload extends PlayerTrackingPayload {
    autoplay?: string;
}

export interface PlayerListenedTimeTrackingPayload extends PlayerListeningTrackingPayload {
    listenedTime: number;
}

export class PlayerAudioStartData extends GtmTrackingData<PlayerListeningTrackingPayload> {
    constructor(payload: PlayerListeningTrackingPayload) {
        super('spela', payload);
    }
}

export class PlayerSuccessfulAudioStartData extends GtmTrackingData<PlayerListeningTrackingPayload> {
    constructor(payload: PlayerListeningTrackingPayload) {
        super('lyssnat5sek', payload);
    }
}

export class PlayerSuccessfulAudioStart10Data extends GtmTrackingData<PlayerListeningTrackingPayload> {
    constructor(payload: PlayerListeningTrackingPayload) {
        super('lyssnat10sek', payload);
    }
}

export class PlayerSuccessfulAudioStart30Data extends GtmTrackingData<PlayerListeningTrackingPayload> {
    constructor(payload: PlayerListeningTrackingPayload) {
        super('lyssnat30sek', payload);
    }
}

export class PlayerSuccessfulAudioStart60Data extends GtmTrackingData<PlayerListeningTrackingPayload> {
    constructor(payload: PlayerListeningTrackingPayload) {
        super('lyssnat60sek', payload);
    }
}

export class PlayerListenedTimeData extends GtmTrackingData<PlayerListenedTimeTrackingPayload> {
    constructor(payload: PlayerListenedTimeTrackingPayload) {
        super('lyssnar', payload);
    }
}

export class PlayerCompletedListeningData extends GtmTrackingData<PlayerListeningTrackingPayload> {
    constructor(payload: PlayerListeningTrackingPayload) {
        super('avlyssnat', payload);
    }
}
export class PlayerExpandedTrackingData extends GtmTrackingData<PlayerTrackingPayload> {
    constructor(payload: PlayerTrackingPayload) {
        super('spelaren_oppna', payload);
    }
}

export class PlayerRewindTrackingData extends GtmTrackingData<PlayerTrackingPayload> {
    constructor(payload: PlayerTrackingPayload) {
        super('spelaren_bak15sek', payload);
    }
}

export class PlayerFastforwardTrackingData extends GtmTrackingData<PlayerTrackingPayload> {
    constructor(payload: PlayerTrackingPayload) {
        super('spelaren_fram15sek', payload);
    }
}

export class PlayerPreviousAudioTrackingData extends GtmTrackingData<PlayerTrackingPayload> {
    constructor(payload: PlayerTrackingPayload) {
        super('spelaren_skip_bak', payload);
    }
}

export class PlayerNextAudioTrackingData extends GtmTrackingData<PlayerTrackingPayload> {
    constructor(payload: PlayerTrackingPayload) {
        super('spelaren_skip_fram', payload);
    }
}

export class PlayerSeekTrackingData extends GtmTrackingData<PlayerTrackingPayload> {
    constructor(payload: PlayerTrackingPayload) {
        super('spelaren_spola', payload);
    }
}

export class PlayerSeekToLiveTrackingData extends GtmTrackingData<PlayerTrackingPayload> {
    constructor(payload: PlayerTrackingPayload) {
        super('spelaren_spola_till_direkt', payload);
    }
}

export class PlayerAutoPlayToggleTrackingData extends GtmTrackingData<PlayerTrackingPayload> {
    constructor(payload: PlayerTrackingPayload) {
        super('autoplay_toggle', payload);
    }
}
