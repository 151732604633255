import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { CategoryTagClickedEvent } from '@/common/custom-events/category-tag-clicked-event';
import { CategoryTagClickedTrackingData, CategoryTagClickedTrackingPayload } from './gtm-events';

export class TrackCategoryTagClickedEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleFn = (e: CategoryTagClickedEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(CategoryTagClickedEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(CategoryTagClickedEvent.EventName, this.handleFn);
    }

    handle(event: CategoryTagClickedEvent): void {
        const payload = event.detail;

        if (!payload?.trackingMetadata) {
            return;
        }

        const trackingMetadata = payload.trackingMetadata;

        const eventMetadata: CategoryTagClickedTrackingPayload = {
            pageType: this.pageMetadataService.pageView?.pageType,
            channelName: trackingMetadata.channelName,
            channelId: trackingMetadata.channelId,
            programName: trackingMetadata.programName,
            programId: trackingMetadata.programId,
            programCategory: trackingMetadata.category
        };

        const gtmEvent = new CategoryTagClickedTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
