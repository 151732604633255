import { consentService } from '@/common/consent-service';
import siteSettings from '@/common/site-settings';
import { AudioMetadata, AudioType, Branding } from '@/components/models';
import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import {
    PlayerAudioStartData,
    PlayerTrackingPayload,
    PlayerListenedTimeData,
    PlayerSuccessfulAudioStart10Data,
    PlayerSuccessfulAudioStart30Data,
    PlayerSuccessfulAudioStart60Data,
    PlayerSuccessfulAudioStartData,
    PlayerListeningTrackingPayload,
    PlayerListenedTimeTrackingPayload
} from '@/tracking/gtm/gtm-player-events';
import { PlayerTrackingAction } from '@/tracking/track-types';
import { GtmTrackingData } from './gtm-events';

type ChannelInfo = {
    channelID?: string;
    channelName?: string;
};

export type GtmAudioType = 'direkt' | 'klipp' | 'avsnitt';

class GtmPlayerTracker {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public trackLiveListening(context: any, trackType: PlayerTrackingAction, trackValue?: number): void {
        if (!consentService.isStatisticsAllowed() || !siteSettings.googleTagManagerEnabled) {
            return;
        }

        const payload: PlayerListeningTrackingPayload = {
            audioType: this.getAudioType(context.getters.type),
            channelName: context.getters.channelName,
            channelID: context.getters.channelId
        };

        let trackingData: GtmTrackingData<PlayerListeningTrackingPayload> | undefined = undefined;

        if (trackType === 'lyssnar') {
            const trackingPayload: PlayerListenedTimeTrackingPayload = {
                ...payload,
                listenedTime: trackValue ?? 0
            };
            trackingData = new PlayerListenedTimeData(trackingPayload);
        } else if (trackType === 'spela') {
            trackingData = new PlayerAudioStartData(payload);
        } else if (trackType === 'lyssnat5sek') {
            trackingData = new PlayerSuccessfulAudioStartData(payload);
        } else if (trackType === 'lyssnat10sek') {
            trackingData = new PlayerSuccessfulAudioStart10Data(payload);
        } else if (trackType === 'lyssnat30sek') {
            trackingData = new PlayerSuccessfulAudioStart30Data(payload);
        } else if (trackType === 'lyssnat60sek') {
            trackingData = new PlayerSuccessfulAudioStart60Data(payload);
        }

        if (trackingData) {
            googleTagManager.track(trackingData);
        }
    }

    public createGtmPayload(metadata: AudioMetadata, playlistTitle?: string): PlayerTrackingPayload {
        const playlist = playlistTitle ? playlistTitle : undefined;
        const audioType = this.getAudioType(metadata.statistics.audioType);
        const channelInfo = this.getChannelInfo(metadata, audioType);
        const audioName = this.getAudioName(audioType, metadata);
        const publicationID = metadata.statistics.publicationId ? metadata.statistics.publicationId : undefined;
        const audioId = audioType != 'direkt' && metadata.id != null ? metadata.id?.toString() : undefined;

        const payload: PlayerTrackingPayload = {
            audioID: audioId,
            audioType: audioType,
            channelName: channelInfo.channelName,
            channelID: channelInfo.channelID,
            programName: metadata.programName,
            programID: metadata.programId,
            audioName: audioName,
            publicationID: publicationID,
            audioLength: metadata.duration,
            daysFromPublish: metadata.statistics.backendTrackingMetadata?.daysSincePublish,
            playlist: playlist,
            programCategory: metadata.statistics.backendTrackingMetadata?.category ?? undefined,
            publishDate: metadata.statistics.backendTrackingMetadata?.publishDate
        };

        return payload;
    }

    private getAudioType(audioType: AudioType): GtmAudioType | undefined {
        switch (audioType) {
            case AudioType.Channel:
            case AudioType.Extra:
            case AudioType.Music:
                return 'direkt';
            case AudioType.Clip:
            case AudioType.Publication:
                return 'klipp';
            case AudioType.Episode:
            case AudioType.Secondary:
                return 'avsnitt';
            default:
                return undefined;
        }
    }

    private getAudioName(audioType: string | undefined, metadata: AudioMetadata): string | undefined {
        switch (audioType) {
            case 'klipp':
                return metadata.statistics.backendTrackingMetadata?.clipName;
            case 'avsnitt':
                return metadata.statistics.backendTrackingMetadata?.episodeName;
            default:
                return undefined;
        }
    }

    private getChannelInfo(metadata: AudioMetadata, audioType?: GtmAudioType): ChannelInfo {
        if (metadata.branding === Branding.Default) {
            return {
                channelID: undefined,
                channelName: undefined
            };
        }

        return {
            channelID: audioType === 'direkt' ? metadata.id?.toString() : metadata.channelId?.toString(),
            channelName: metadata.branding
        };
    }
}

export const gtmPlayerTracker = new GtmPlayerTracker();
