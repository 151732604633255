






    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { Branding } from '@/components/models/Branding';
    import { themeHelper } from '@/common/theme-helper';

    @Component
    export default class Handle extends Vue {
        @Prop(Number) position!: number;
        @Prop(Boolean) isHandleActive!: boolean;
        @Prop({ type: String as () => Branding }) branding?: Branding;

        get brandingBackgroundColor(): string {
            return themeHelper.getBgColorClass(this.branding);
        }

        get handleCss(): string {
            return `left: ${this.position}%`;
        }
    }
