






    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class BrandedHeading extends Vue {
        @Prop({
            default: 'div',
            validator: value => {
                return value.match(/(div|span|h2)/);
            }
        })
        readonly tag!: string;
    }
