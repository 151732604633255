






    import { Vue, Component } from 'vue-property-decorator';
    import anime from 'animejs';

    @Component
    export default class Scale extends Vue {
        onEnter(el: HTMLElement): void {
            anime({
                targets: el,
                scale: [0, 1],
                duration: 250,
                easing: 'easeOutCubic'
            });
        }

        onLeave(el: HTMLElement, doneFunc: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                scale: [1, 0],
                duration: 250,
                easing: 'easeOutCubic',
                complete: doneFunc
            });
        }
    }
