













    import { Mixins, Component, Prop } from 'vue-property-decorator';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import Flex from '@/components/shared/Flex.vue';
    import Hyphen from '@/components/shared/ascii/Hyphen.vue';
    import UiCaption from '@/styleguide/elements/UiCaption.vue';
    import UiOverline from '@/styleguide/elements/UiOverline.vue';
    import Note from '@/components/shared/ascii/Note.vue';
    import Thumbnail from '@/components/shared/Thumbnail.vue';

    @Component({
        components: {
            Flex,
            Hyphen,
            UiCaption,
            Note,
            Thumbnail,
            UiOverline
        }
    })
    export default class PlayerLiveMarker extends Mixins(MetadataMixin, PlaybackMixin) {
        @Prop(Number) position!: number;

        get css(): string {
            return `left: ${this.position}%`;
        }
    }
