import MetadataApi from '@/api/metadata-api';
import { AudioKey, AudioMetadata, AudioQuality } from '@/components/models';
import { metadataMutations } from '@/store/metadata/MetadataMutations';
import { Commit } from 'vuex';
import { PlayerMode } from '@/enums/PlayerMode';
import { reqMapper, audioMapper } from './mappers';

export type FetchFromApiPayload = {
    key?: AudioKey;
    audios: Record<string, AudioMetadata>;
    quality: AudioQuality;
    isEmbed: boolean;
    mode: PlayerMode;
    commit: Commit;
};

export async function fetchSingleMetadataFromApi({
    key,
    audios,
    quality,
    isEmbed,
    mode,
    commit
}: FetchFromApiPayload): Promise<void> {
    if (!key || audios[key.id]) {
        return;
    }
    const response = await MetadataApi.getMetadata(reqMapper(key, quality), isEmbed, mode);
    const audioMetadata = audioMapper(response, key.publicationId || null);

    commit(metadataMutations.AddAudio, audioMetadata);
}
