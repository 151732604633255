import { customEventInit } from './custom-events-common';

interface MenuClickedPayload {
    linkTitle?: string;
}

export class MenuClickedEvent extends CustomEvent<MenuClickedPayload> {
    public static EventName = 'menu-clicked' as const;
    constructor(linkTitle: string) {
        super(
            MenuClickedEvent.EventName,
            customEventInit({
                linkTitle: linkTitle
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [MenuClickedEvent.EventName]: MenuClickedEvent;
    }
}
