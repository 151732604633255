import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { ModuleClickedTrackingData, ModuleClickedTrackingPayload } from './gtm-events';
import { ModuleClickedEvent } from '@/common/custom-events/module-clicked-event';

export class TrackModuleClickedEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleFn = (e: ModuleClickedEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(ModuleClickedEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(ModuleClickedEvent.EventName, this.handleFn);
    }

    handle(event: ModuleClickedEvent): void {
        const payload = event.detail;

        if (!payload) {
            return;
        }

        const eventMetadata: ModuleClickedTrackingPayload = {
            moduleType: payload.moduleType,
            moduleName: payload.moduleName,
            pageType: this.pageMetadataService.pageView?.pageType,
            contentType: payload.contentType == '' ? undefined : payload.contentType,
            programName: payload.programName == '' ? undefined : payload.programName,
            programId: payload.programId == '' ? undefined : payload.programId
        };

        if (payload.programCategory) {
            eventMetadata.programCategory = payload.programCategory;
        }

        const gtmEvent: ModuleClickedTrackingData = new ModuleClickedTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
