export enum MetadataActions {
    SetQueue = 'metadata/setQueue',
    SetSingleAudio = 'metadata/setSingleAudio',
    Clear = 'metadata/clear',
    RestoreFromStorage = 'metadata/restoreFromStorage',
    QualityChange = 'metadata/qualityChange',
    RearrangeQueue = 'metadata/rearrangeQueue',
    SetCurrentIndex = 'metadata/setCurrentIndex',
    FetchCurrentAndAdjacentMetadata = 'metadata/fetchCurrentAndAdjacentMetadata'
}
