import { PlayerState } from '@/store/PlayerState';
import playerLegacyStates from '@/enums/player-states';

export function legacyPlayerStateMapper(playerState: string): PlayerState {
    if (playerState === playerLegacyStates.PLAYING) {
        return PlayerState.Playing;
    }
    if (playerState === playerLegacyStates.PAUSED) {
        return PlayerState.Paused;
    }
    if (playerState === playerLegacyStates.LOADING) {
        return PlayerState.Loading;
    }
    if (playerState === playerLegacyStates.ERROR) {
        return PlayerState.Error;
    }
    if (playerState === playerLegacyStates.RECONNECTING) {
        return PlayerState.Reconnecting;
    }
    return PlayerState.Unknown;
}
