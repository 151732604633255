


















    import { Mixins, Component, Prop } from 'vue-property-decorator';
    import anime from 'animejs';
    import SvgMixin from '@/components/mixins/SvgMixin';

    export type PreferredMode = 'lightmode' | 'darkmode' | 'auto';

    @Component
    export default class UiPlayPauseIcon extends Mixins(SvgMixin) {
        @Prop({ default: false }) readonly isPlaying!: boolean;
        @Prop({ default: false }) readonly small!: boolean;

        @Prop({
            default: 'auto',
            validator: value => {
                return value.match(/(lightmode|darkmode|auto)/);
            }
        })
        readonly preferredMode!: PreferredMode;

        get themeClasses(): { [key: string]: boolean } {
            return {
                'play-icon--is-always-lightmode': this.preferredMode === 'lightmode',
                'play-icon--is-always-darkmode': this.preferredMode === 'darkmode'
            };
        }

        iconName(name: string): string {
            return `${this.svgSpriteUrl}${name}`;
        }

        playIconLeave(el: HTMLElement, done: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                opacity: [1, 0],
                scale: [1, 0.6],
                duration: 250,
                easing: 'easeOutBack',
                complete: done
            });
        }

        playIconEnter(el: HTMLElement, done: (anim: anime.AnimeInstance) => void): void {
            anime({
                targets: el,
                opacity: [0, 1],
                scale: [0.6, 1],
                duration: 250,
                easing: 'easeOutBack',
                complete: done
            });
        }
    }
