import { SwipedToLastEvent } from '@/common/custom-events/swiped-to-last-event';
import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { ModuleTrackingPayload, SwipedToLastTrackingData } from './gtm-events';

export class TrackSwipedToLastEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleFn = (e: SwipedToLastEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(SwipedToLastEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(SwipedToLastEvent.EventName, this.handleFn);
    }

    handle(event: SwipedToLastEvent): void {
        const payload = event.detail;

        if (!payload) {
            return;
        }

        const eventMetadata: ModuleTrackingPayload = {
            moduleType: 'karusell',
            moduleName: payload.moduleName,
            pageType: this.pageMetadataService.pageView?.pageType,
            heading: undefined,
            modulePosition: undefined
        };

        const gtmEvent: SwipedToLastTrackingData = new SwipedToLastTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
