import deviceDetection from '@/common/device-detection';
import siteSettings from '@/common/site-settings';

export default {
    hasHlsSupportAsync,
    hasNativeHlsSupport
};

async function hasHlsSupportAsync(): Promise<boolean> {
    return isHlsEnabled() && isDeviceEnabled() && !isBrowserBlocked() && (await canDevicePlayHlsAsync()); // checking this last to try to avoid loading hls.js if not required
}

function hasNativeHlsSupport(): boolean {
    return deviceDetection.isIos || deviceDetection.isSafari;
}

function isHlsEnabled(): boolean {
    return siteSettings.isHlsEnabled;
}

async function canDevicePlayHlsAsync(): Promise<boolean> {
    if (hasNativeHlsSupport()) {
        return true;
    }

    try {
        // webpackChunkName comment is needed for readable js filename for hlsjs when importing dynamic modules
        const { default: hls } = await import(/* webpackChunkName: "hlsjs" */ 'hls.js');
        return hls.isSupported();
    } catch (e) {
        return false;
    }
}

function isDeviceEnabled(): boolean {
    return (
        deviceDetection.isChrome ||
        deviceDetection.isAndroid ||
        deviceDetection.isIos ||
        deviceDetection.isSafari ||
        deviceDetection.isFirefox ||
        deviceDetection.isEdge
    );
}

function isBrowserBlocked(): boolean {
    return (
        !!deviceDetection.majorBrowserVersion &&
        ((deviceDetection.isSafari && deviceDetection.majorBrowserVersion < 10) ||
            (deviceDetection.isFirefox && deviceDetection.majorBrowserVersion < 49))
    );
}
