import httpClient from '@/common/http-client';
import siteSettings from '@/common/site-settings';

export default {
    getTrafficMessages(params: TrafficMessagesRequest) {
        const url = siteSettings.apiUrl + 'v2/traffic/messages';
        return httpClient.get<TrafficMessagesResponse>(url, params);
    },
    getTrafficAreas(params: TrafficAreasRequest) {
        const url = siteSettings.apiUrl + 'v2/traffic/areas';
        return httpClient.get<TrafficAreasResponse>(url, params);
    },
    getLocalTraffic(params: LocalTrafficRequest) {
        const url = '/ajax/localtraffic';
        return httpClient.get<LocalTrafficResponse>(url, params);
    }
};

type TrafficMessagesRequest = {
    format: string;
    pagination: string;
};

type TrafficAreasRequest = {
    format: string;
    latitude: number;
    longitude: number;
};

type TrafficMessagesResponse = {
    messages: TrafficMessage[];
};

export type TrafficMessage = {
    category: number;
    createddate: string;
    description: string;
    exactlocation: string;
    id: number;
    latitude: number;
    longitude: number;
    priority: number;
    subcategory: string;
    title: string;
};

type TrafficAreasResponse = {
    area: TrafficArea;
};

type TrafficArea = {
    name: string;
    radius: number;
    trafficdepartmentunitid: number;
    zoom: number;
};

type LocalTrafficRequest = {
    localId: number;
};

export type LocalTrafficResponse = {
    localName: string;
    url: string;
    items: LocalTrafficItem[];
};

export type LocalTrafficItem = {
    id: number;
    time: string;
    title: string;
    url: string;
};
