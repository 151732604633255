import { MetadataRequest } from '@/api/metadata-api';
import { AudioMetadataSong, AudioResponseWithMetadata } from '@/api/responses/AudioResponse';
import { AudioKey, AudioMetadata, AudioQuality, Track } from '@/components/models/';

export function reqMapper(audioKey: AudioKey, quality: AudioQuality | undefined): MetadataRequest {
    return {
        id: audioKey.id,
        type: audioKey.type,
        publicationId: audioKey.publicationId,
        quality
    };
}

export function audioMapper(res: AudioResponseWithMetadata, publicationId: number | null): AudioMetadata {
    return {
        src: res.audioUrl,
        id: res.metadata.id,
        episodeTitle: res.metadata.items[0].subtitle,
        programName: res.metadata.items[0].title,
        programId: res.metadata.items[0].programid,
        url: res.metadata.items[0].readmoreurl,
        tracks: res.metadata.currentSongs.map(trackMapper),
        imageUrl: res.metadata.items[0].displayimageurl,
        thumbnailImageUrl: res.metadata.items[0].thumbnailimageurl,
        branding: res.metadata.items[0].channelname,
        channelId: res.metadata.items[0].channelid,
        date: res.metadata.items[0].displayDate,
        duration: res.metadata.items[0].duration,
        statistics: {
            backendTrackingMetadata: res.metadata.items[0].statisticsaudio,
            audioType: res.metadata.audiotype,
            audioId: res.metadata.audioId,
            publicationId
        },
        isPod: res.metadata.items[0].ispod
    };
}

export function trackMapper(trackResponse: AudioMetadataSong): Track {
    return {
        title: trackResponse?.title,
        artist: trackResponse?.artist,
        albumartUrl: trackResponse?.albumarturl,
        starttime: trackResponse?.starttime_seconds,
        endtime: trackResponse?.endtime_seconds
    };
}

export default { trackMapper, audioMapper };
