export enum LegacyPlayerAction {
    Volume = 'player/volume',
    VolumeDown = 'player/volumeDown',
    VolumeUp = 'player/volumeUp',
    ChangeQuality = 'player/changeQuality',
    SetQuality = 'player/setQuality',

    PlayOrPause = 'player/playOrPause',
    Init = 'player/init',
    Load = 'player/load',
    Unload = 'player/unload',
    CloseOverlay = 'player/closeOverlay',
    SetVisibilityOnScroll = 'player/setVisibilityOnScroll'
}
