import { customEventInit } from './custom-events-common';

interface SwipedEventPayload {
    moduleName: string;
}

export class SwipedEvent extends CustomEvent<SwipedEventPayload> {
    public static EventName = 'carousel-swiped' as const;

    constructor(moduleName: string) {
        super(
            SwipedEvent.EventName,
            customEventInit({
                moduleName: moduleName
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [SwipedEvent.EventName]: SwipedEvent;
    }
}
