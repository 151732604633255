import { ShowAllClickedEvent } from '@/common/custom-events/show-all-clicked-event';
import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { ModuleTrackingPayload, ShowAllClickedTrackingData } from './gtm-events';

export class TrackShowAllClickedEventListener implements DisposableEventListener {
    pageMetadataService: PageMetadataService;
    private handleFn = (e: ShowAllClickedEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(ShowAllClickedEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(ShowAllClickedEvent.EventName, this.handleFn);
    }

    handle(event: ShowAllClickedEvent): void {
        const payload = event.detail;

        if (!payload) {
            return;
        }

        const eventMetadata: ModuleTrackingPayload = {
            moduleType: payload.moduleType,
            moduleName: payload.moduleName,
            pageType: this.pageMetadataService.pageView?.pageType,
            heading: payload.moduleHeading === '' ? undefined : payload.moduleHeading,
            modulePosition: undefined
        };

        const gtmEvent: ShowAllClickedTrackingData = new ShowAllClickedTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
