export enum AudioActions {
    Pause = 'audio/pause',
    Seek = 'audio/seek',
    Play = 'audio/play',
    SetVolume = 'audio/setVolume',
    Set = 'audio/set',
    Unload = 'audio/unload',
    ResetPosition = 'audio/resetPosition',
    TouchForLegacySafari = 'audio/touchForLegacySafari'
}
