import { customEventInit } from './custom-events-common';

type ExternalLinkClickedEventPayload = {
    url: string;
    title: string;
    deepUrl?: string;
};

export class ExternalLinkClickedEvent extends CustomEvent<ExternalLinkClickedEventPayload> {
    public static EventName = 'external-link-clicked' as const;
    constructor(url: string, title: string, deepUrl?: string) {
        super(
            ExternalLinkClickedEvent.EventName,
            customEventInit({
                url,
                title,
                deepUrl
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [ExternalLinkClickedEvent.EventName]: ExternalLinkClickedEvent;
    }
}
