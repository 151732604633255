import axios, { AxiosError } from 'axios';
import { convertPropertiesToLowercase } from './object-converter';

export default {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    get<T>(url: string, params?: any): Promise<IHttpResponse<T>> {
        const lowercaseParams = convertPropertiesToLowercase(params);
        return axios.get<T>(url.toLowerCase(), {
            params: lowercaseParams
        });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    post<T>(url: string, data?: any): Promise<IHttpResponse<T>> {
        return axios.post<T>(url, data);
    },
    all<T>(promises: Promise<IHttpResponse<T>>[]): Promise<IHttpResponse<T>[]> {
        return axios.all(promises);
    },
    isAxiosError(error: unknown): error is AxiosError {
        return axios.isAxiosError(error);
    }
};

export interface IHttpResponse<T> {
    data: T;
    status: number;
    request?: XMLHttpRequest;
}
