






    import { Vue, Component, Prop } from 'vue-property-decorator';
    import siteSettings from '@/common/site-settings';

    @Component
    export default class SvgImage extends Vue {
        @Prop({ default: '' }) readonly name!: string;
        @Prop(String) readonly fill!: 'white' | 'inherit' | undefined;

        get svgUrl(): string {
            const spriteName = siteSettings.svgSpriteName;
            return `${spriteName}#sprite-${this.name}`;
        }
    }
