import { AudioEvents } from '@/enums';
import { AudioMutations } from '@/store/audio/AudioMutations';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { Commit } from 'vuex';

export class AudioStateEventListener implements EventListenerObject, DisposableEventListener {
    private commit: Commit;
    private audioElement: HTMLAudioElement;

    private readonly events: AudioEvents[] = [
        AudioEvents.TimeUpdate,
        AudioEvents.Play,
        AudioEvents.Playing,
        AudioEvents.Pause,
        AudioEvents.Ended,
        AudioEvents.DurationChange,
        AudioEvents.Error,
        AudioEvents.VolumeChange,
        AudioEvents.Progress,
        AudioEvents.Stalled,
        AudioEvents.LoadStart
    ];

    constructor(commit: Commit, audio: HTMLAudioElement) {
        this.commit = commit;
        this.audioElement = audio;
        this.events.map(e => this.audioElement.addEventListener(e, this));
    }

    public dispose() {
        this.events.map(e => this.audioElement.removeEventListener(e, this));
    }

    public handleEvent(event: Event) {
        switch (event.type) {
            case AudioEvents.TimeUpdate:
                this.commit(AudioMutations.SetPosition);
                this.commit(AudioMutations.SetIsStalled, false);
                break;
            case AudioEvents.Play:
            case AudioEvents.Playing:
                this.commit(AudioMutations.SetIsPlaying, true);
                this.commit(AudioMutations.SetIsStalled, false);
                this.commit(AudioMutations.SetError);
                break;
            case AudioEvents.Pause:
                this.commit(AudioMutations.SetIsPlaying, false);
                this.commit(AudioMutations.SetIsStalled, false);
                break;
            case AudioEvents.Ended:
                this.commit(AudioMutations.SetIsPlaying, false);
                this.commit(AudioMutations.SetIsStalled, false);
                this.commit(AudioMutations.ResetPosition);
                break;
            case AudioEvents.DurationChange:
                this.commit(AudioMutations.SetDuration);
                break;
            case AudioEvents.Error:
                this.commit(AudioMutations.SetError);
                this.commit(AudioMutations.SetIsPlaying, false);
                this.commit(AudioMutations.SetIsStalled, false);
                break;
            case AudioEvents.VolumeChange:
                this.commit(AudioMutations.SetVolume);
                break;
            case AudioEvents.Progress:
                this.commit(AudioMutations.SetBuffered);
                break;
            case AudioEvents.Stalled:
                this.commit(AudioMutations.SetIsStalled, true);
                break;
            case AudioEvents.LoadStart:
                this.commit(AudioMutations.SetError);
                break;
            default:
                break;
        }
    }
}
