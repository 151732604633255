import dateFormatter from '@/common/date-formatter';
import { secondsToTime } from '@/common/player-time-helper';
import { LegacyLivePlayerGetter } from '@/enums/LegacyLivePlayerGetter';
import { AudioGetters } from '@/store';
import { RootGetters } from '@/store/RootGetters';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PlayerProgressMixin extends Vue {
    protected get duration(): number {
        return this.$store.getters[RootGetters.isOndemandPlayerMode] ? this.$store.getters[AudioGetters.Duration] : 0;
    }

    protected get durationFormatted(): string {
        return this.duration ? secondsToTime(this.duration) : '--:--';
    }

    protected get streamPosition(): number {
        return this.$store.getters[RootGetters.isOndemandPlayerMode] ? this.$store.getters[AudioGetters.Position] : 0;
    }

    protected get streamPositionFormatted(): string {
        return this.duration ? secondsToTime(this.streamPosition) : '--:--';
    }

    protected get buffer(): number {
        return this.$store.getters[RootGetters.isOndemandPlayerMode] ? this.$store.getters[AudioGetters.Buffered] : 0;
    }

    protected get catchupProgressPercentage(): number {
        const startTime = this.$store.getters[LegacyLivePlayerGetter.episodeStartTime];
        const endTime = this.$store.getters[LegacyLivePlayerGetter.episodeEndTime];
        const streamPositionTime = this.$store.getters[LegacyLivePlayerGetter.currentTime];

        if (!startTime || !endTime || !streamPositionTime) {
            return 0;
        }

        const duration = (endTime.getTime() - startTime.getTime()) / 1000;

        if (!duration) {
            return 0;
        }

        const catchUp = (streamPositionTime.getTime() - startTime.getTime()) / 1000;
        const percentage = (catchUp / duration) * 100;

        return percentage <= 100 ? percentage : 100;
    }

    protected get liveStartTimeFormatted(): string {
        const startTime = this.$store.getters[LegacyLivePlayerGetter.episodeStartTime];

        if (!startTime) {
            return '--.--';
        }

        return dateFormatter.shortTime(new Date(startTime));
    }

    protected get liveEndTimeFormatted(): string {
        const endTime = this.$store.getters[LegacyLivePlayerGetter.episodeEndTime];

        if (!endTime) {
            return '--.--';
        }

        return dateFormatter.shortTime(new Date(endTime));
    }

    protected get isBroadcastLive(): boolean {
        return (
            (this.$store.getters[LegacyLivePlayerGetter.isHlsActive] &&
                this.$store.getters[LegacyLivePlayerGetter.isHlsStreamPositionLive]) ||
            this.$store.getters[LegacyLivePlayerGetter.isIcecastActive]
        );
    }

    protected get isBroadcastCatchup(): boolean {
        return (
            this.$store.getters[LegacyLivePlayerGetter.isHlsActive] &&
            !this.$store.getters[LegacyLivePlayerGetter.isHlsStreamPositionLive]
        );
    }

    protected get livePositionTime(): Date | null {
        return this.$store.getters[LegacyLivePlayerGetter.LivePositionTime];
    }

    $store: any;
}
