export enum LegacyLivePlayerGetter {
    isHlsStreamPositionLive = 'player/live/hls/isLive',
    subtitle = 'player/live/subtitle',
    programId = 'player/live/programId',
    title = 'player/live/title',
    episodeStartTime = 'player/live/episodeStartTime',
    episodeEndTime = 'player/live/episodeEndTime',
    channelTheme = 'player/live/channelTheme',
    scheduleItems = 'player/live/scheduleItems',
    state = 'player/live/playerState',
    isHlsEnabled = 'player/live/isHlsEnabled',
    StatusMessage = 'player/live/statusMessage',
    PlayerMessage = 'player/live/playerMessage',
    currentTime = 'player/live/currentTime',
    currentTrack = 'player/live/currentSong',
    currentScheduleItem = 'player/live/currentScheduleItem',
    hlsStreamPosition = 'player/live/hls/streamPosition',
    id = 'player/live/id',
    type = 'player/live/type',
    LivePositionTime = 'player/live/livePositionTime',
    isIcecastActive = 'player/live/icecast/active',
    isHlsActive = 'player/live/hls/isActive',
    isActive = 'player/live/isActive',
    currentAudioKey = 'player/live/audioPlaying'
}
