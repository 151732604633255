export const themeHelper = {
    getThemeClass(theme: string | undefined): string {
        return `th-${theme}`;
    },

    getBgColorClass(theme: string | undefined): string {
        return `th-${theme}-bg-color`;
    },

    getBufferClass(theme: string | undefined): string {
        return `th-${theme}-buffer`;
    },

    getFillClass(theme: string | undefined): string {
        return `th-${theme}-fill-color`;
    }
};
