import { customEventInit } from './custom-events-common';

interface ModuleClickedEventPayload {
    moduleType: string;
    moduleName: string;
    programName: string;
    programId: string;
    programCategory?: string;
    contentType?: string;
}

export class ModuleClickedEvent extends CustomEvent<ModuleClickedEventPayload> {
    public static EventName = 'module-clicked' as const;

    constructor(
        moduleType: string,
        moduleName: string,
        programName: string,
        programId: string,
        programCategory: string,
        contentType: string
    ) {
        super(
            ModuleClickedEvent.EventName,
            customEventInit({
                moduleType: moduleType,
                moduleName: moduleName,
                programName: programName,
                programId: programId,
                programCategory: programCategory,
                contentType: contentType
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [ModuleClickedEvent.EventName]: ModuleClickedEvent;
    }
}
