import httpClient from '@/common/http-client';
import { getUserAgent } from '@/common/client-helper';
import settings from '@/common/site-settings';
import axios from 'axios';
import { LogAppName, LogLevel, LogData } from '@/logging/LogTypes';
import siteSettings from '@/common/site-settings';

export const logger = {
    logDebug: (data: LogData): void => {
        log('debug', data);
    },
    logError: (data: LogData): void => {
        log('error', data);
    }
};

async function log(level: LogLevel, data: LogData): Promise<void> {
    try {
        if (!siteSettings.frontendLoggingEnabled) {
            return;
        }

        if (level === 'error' && data) {
            if (data.error) {
                console.error(data.error);
            } else {
                console.error(data);
            }
        }

        const postData: PostLogData = mapToPostData(level, data);
        const url = '/backend-for-frontend/log';

        await httpClient.post<void>(url, postData);
    } catch (err) {
        console.error('Error logging failed.', err);
    }
}

interface PostLogData {
    level: LogLevel;
    application: LogAppName;
    source?: string;
    message: string;
    url?: string;
    userAgent?: string;
    referrer?: string;
    jsonData?: string;
    version: string;
    exceptionMessage?: string;
    exceptionType?: string;
    stacktrace?: string;
}

function mapToPostData(level: LogLevel, data: LogData): PostLogData {
    const error = createError(data.error);

    const errorUrl = getUrlFromError(error);

    return {
        level: level,
        message: data.message,
        application: data.application || 'js',
        source: data.source,
        jsonData: JSON.stringify(data.data),
        exceptionMessage: error?.message,
        exceptionType: error?.name,
        stacktrace: error?.stack,
        url: data.url || errorUrl || window.location.href,
        referrer: window.location.href,
        userAgent: getUserAgent(),
        version: settings.version
    };
}

function createError(error: unknown): Error | undefined {
    if (error instanceof Error) {
        return error;
    }

    if (typeof error === 'string') {
        return new Error(error);
    }

    return undefined;
}

function getUrlFromError(error?: Error): string | undefined {
    if (axios.isAxiosError(error)) {
        return error.config.url;
    }

    return undefined;
}
