import httpClient, { IHttpResponse } from '@/common/http-client';

export default {
    getChannels(params: SearchChannelsRequest): Promise<IHttpResponse<SearchResponse>> {
        const url = '/search/channels';
        return httpClient.get<SearchResponse>(url, params);
    },
    getPrograms(params: SearchProgramsRequest): Promise<IHttpResponse<SearchResponse>> {
        const url = '/search/programs';
        return httpClient.get<SearchResponse>(url, params);
    },
    getEpisodes(params: SearchEpisodesRequest): Promise<IHttpResponse<SearchResponse>> {
        const url = '/search/episodes';
        return httpClient.get<SearchResponse>(url, params);
    },
    getContent(params: SearchContentRequest): Promise<IHttpResponse<SearchResponse>> {
        const url = '/search/content';
        return httpClient.get<SearchResponse>(url, params);
    },
    getAll(params: SearchRequest): Promise<SearchAllResponse> {
        return httpClient
            .all([this.getChannels(params), this.getPrograms(params), this.getEpisodes(params)])
            .then<SearchAllResponse>(data => {
                return {
                    channels: data[0].data,
                    programs: data[1].data,
                    episodes: data[2].data
                };
            });
    }
};

interface SearchRequest {
    query: string;
    take: number;
}

interface SearchChannelsRequest extends SearchRequest {
    page?: number;
}

interface SearchProgramsRequest extends SearchRequest {
    page?: number;
}

interface SearchEpisodesRequest extends SearchRequest {
    skip?: number;
}

interface SearchContentRequest extends SearchRequest {
    skip?: number;
    interval?: string;
}

export interface SearchAllResponse {
    programs: SearchResponse;
    channels: SearchResponse;
    episodes: SearchResponse;
}

export interface SearchResponse {
    query: string;
    totalHits: number;
    hits: SearchResponseItem[];
}

export interface SearchResponseItem {
    title: string;
    titleWithHighlight: string;
    publishedBy: string;
    url: string;
    imageUrl: string;
    description: string;
    publishDate: string;
}
