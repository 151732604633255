import { customEventInit } from './custom-events-common';

interface ScheduleClickedEventPayload {
    channelName: string;
    channelId: string;
}

export class ScheduleClickedEvent extends CustomEvent<ScheduleClickedEventPayload> {
    public static EventName = 'schedule-clicked' as const;

    constructor(channelName: string, channelId: string) {
        super(
            ScheduleClickedEvent.EventName,
            customEventInit({
                channelName: channelName,
                channelId: channelId
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [ScheduleClickedEvent.EventName]: ScheduleClickedEvent;
    }
}
