import httpClient from '@/common/http-client';

export default {
    async allChannels(): Promise<Channel[]> {
        const url = '/backend-for-frontend/allchannels';
        const response = await httpClient.get<AllChannelsResponse>(url);
        return response.data?.items;
    }
};

export interface AllChannelsResponse {
    items: Channel[];
}

export interface Channel {
    id: number;
    audioType: string;
    title: string;
    imageUrl: string;
    theme: string;
    type: 'additional' | 'local' | 'priority';
    tablaUrl: string;
    url: string;
    logo: string;
}
