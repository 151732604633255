import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { SelectChannelTrackingData, SelectChannelTrackingPayload } from './gtm-events';
import { SelectAdditionalChannelEvent } from '@/common/custom-events/select-channel/select-additional-channel-event';

export class TrackSelectChannelEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleAdditionalFn = (e: SelectAdditionalChannelEvent): void => this.handleAdditional(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(SelectAdditionalChannelEvent.EventName, this.handleAdditionalFn);
    }

    dispose(): void {
        window.removeEventListener(SelectAdditionalChannelEvent.EventName, this.handleAdditionalFn);
    }

    handleAdditional(event: SelectAdditionalChannelEvent): void {
        const eventMetadata: SelectChannelTrackingPayload = {
            moduleType: event.detail.eventSource,
            moduleName: 'välj kanal',
            pageType: this.pageMetadataService.pageView?.pageType,
            channelName: undefined,
            channelId: undefined,
            liveChannel: 'extra',
            modulePosition: undefined
        };

        const gtmEvent = new SelectChannelTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
