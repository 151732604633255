import axios from 'axios';

export async function checkGeoblock(url: string, context: any): Promise<void> {
    const geoMessage =
        'Av sändningsrättsliga skäl kan du inte lyssna på P4 under OS. <a href="https://sverigesradio.se/artikel/7769682">Läs mer</a>';
    const geoHeader = 'geoblockjingel';

    const geoResponse = await axios.head(url);

    if (geoResponse.headers[geoHeader]) {
        context.commit('setPlayerMessage', geoMessage);
    }
}
