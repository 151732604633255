import { GtmTrackingData } from '@/tracking/gtm/gtm-events';
import { PageViewMetadata } from '@/tracking/gtm/tag-manager-types';
import { consentService } from '@/common/consent-service';
import { env } from '@/common/env';
import siteSettings from '@/common/site-settings';
import { pageMetadataService } from '@/common/page-metadata-service';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { TrackModuleStartedEventListener } from '@/tracking/gtm/TrackModuleStartedEventListener';
import { TrackModuleVisibleEventListener } from '@/tracking/gtm/TrackModuleVisibleEventListener';
import { TrackShowAllClickedEventListener } from '@/tracking/gtm/TrackShowAllClickedEventListener';
import { TrackSwipedEventListener } from '@/tracking/gtm/TrackSwipedEventListener';
import { TrackSwipedToLastEventListener } from '@/tracking/gtm/TrackSwipedToLastEventListener';
import { TrackSelectChannelEventListener } from '@/tracking/gtm/TrackSelectChannelEventListener';
import { TrackChangeChannelEventListener } from '@/tracking/gtm/TrackChangeChannelEventListener';
import { TrackExternalLinkClickedEventListener } from '@/tracking/gtm/TrackExternalLinkClickedEventListener';
import { TrackPlayerInteractionEventListener } from '@/tracking/gtm/TrackPlayerInteractionEventListener';
import { TrackModuleClickedEventListener } from '@/tracking/gtm/TrackModuleClickedEventListener';
import { TrackEpisodeButtonsClickedEventListener } from '@/tracking/gtm/TrackEpisodeButtonsClickedEventListener';
import { TrackCategoryButtonClickedEventListener } from '@/tracking/gtm/TrackCategoryButtonClickedEventListener';
import { TrackSearchEventListener } from '@/tracking/gtm/TrackSearchEventListener';
import { TrackCategoryTagClickedEventListener } from '@/tracking/gtm/TrackCategoryTagClickedEventListener';
import { TrackScheduleClickedEventListener } from '@/tracking/gtm/TrackScheduleClickedEventListener';
import { TrackMenuOpenEventListener } from '@/tracking/gtm/TrackMenuOpenEventListener';
import { TrackMenuClickedEventListener } from '@/tracking/gtm/TrackMenuClickedEventListener';
import { TrackShowMoreClickedEventListener } from '@/tracking/gtm/TrackShowMoreClickedEventListener';

export class GoogleTagManager {
    private trackingEvents: DisposableEventListener[] = [];

    public install(): void {
        if (!this.shouldTrack()) {
            return;
        }

        this.registerTrackingEvents();
    }

    public trackPage(metadata: PageViewMetadata): void {
        if (!this.shouldTrack()) {
            return;
        }

        consentService.pushConsentModeToDataLayer();

        const event = {
            event: 'page_view',
            ...metadata
        };

        this.addToDataLayer(event);
    }

    public track<T>(event: GtmTrackingData<T>): void {
        if (!this.shouldTrack() || !event) {
            return;
        }

        const gtmEvent = {
            event: event.eventName,
            ...event.payload
        };

        this.addToDataLayer(gtmEvent);
    }

    private shouldTrack(): boolean {
        return (
            consentService.isStatisticsAllowed() && siteSettings.googleTagManagerEnabled && siteSettings.googleTagManagerId != ''
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private addToDataLayer(event: any): void {
        window.dataLayer.push(event);

        if (env.isDev()) {
            console.info(event);
        }
    }

    private registerTrackingEvents(): void {
        this.trackingEvents.push(new TrackSwipedEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackSwipedToLastEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackShowAllClickedEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackModuleVisibleEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackModuleStartedEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackModuleClickedEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackSelectChannelEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackChangeChannelEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackExternalLinkClickedEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackPlayerInteractionEventListener());
        this.trackingEvents.push(new TrackEpisodeButtonsClickedEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackCategoryButtonClickedEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackSearchEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackCategoryTagClickedEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackScheduleClickedEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackMenuOpenEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackMenuClickedEventListener(pageMetadataService));
        this.trackingEvents.push(new TrackShowMoreClickedEventListener(pageMetadataService));
    }
}

export const googleTagManager = new GoogleTagManager();
