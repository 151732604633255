






    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiLiveLabel extends Vue {
        @Prop({ default: 'Direkt' }) readonly title!: string;
    }
