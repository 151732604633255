import api, { LocalNewsResult } from '@/api/news-api';
import { StoreOptions } from 'vuex';
export default {
    namespaced: true,
    state: {
        data: null
    },
    getters: {
        items: state => (state.data ? state.data.items : [])
    },
    actions: {
        load(context, localChannelId): void {
            api.getLocalNews({
                localId: localChannelId
            }).then(result => {
                context.commit('setData', result.data);
            });
        }
    },
    mutations: {
        setData(state, data): void {
            state.data = data;
        }
    }
} as StoreOptions<State>;

type State = {
    data: LocalNewsResult | null;
};
