






    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component({
        name: 'Flex'
    })
    export default class Flex extends Vue {
        @Prop(String) readonly alignItems: string | undefined;
        @Prop(String) readonly justify: string | undefined;
        @Prop(Boolean) readonly divider: boolean | undefined;
        @Prop({
            default: 'div'
        })
        readonly tag!: string;

        get alignCss(): string | undefined {
            return this.alignItems ? `align-${this.alignItems}` : undefined;
        }

        get justifyCss(): string | undefined {
            return this.justify ? `justify-${this.justify}` : undefined;
        }
    }
