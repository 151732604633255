import { AudioMetadata } from '@/components/models/AudioMetadata';
import { customEventInit } from '../custom-events-common';
import { PlayerInteractionPayload } from './player-interaction-payload';

export class PlayerPreviousAudioEvent extends CustomEvent<PlayerInteractionPayload> {
    public static EventName = 'player-previous-audio' as const;

    constructor(audioMetadata: AudioMetadata, playlistTitle: string) {
        super(
            PlayerPreviousAudioEvent.EventName,
            customEventInit({
                audioMetadata: audioMetadata,
                playlistTitle: playlistTitle
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [PlayerPreviousAudioEvent.EventName]: PlayerPreviousAudioEvent;
    }
}
