import { customEventInit } from './custom-events-common';

interface ModuleVisibleEventPayload {
    moduleType: string;
    moduleName: string;
    minVisiblePercentage: number;
}

export class ModuleVisibleEvent extends CustomEvent<ModuleVisibleEventPayload> {
    public static EventName = 'module-visible' as const;

    constructor(moduleType: string, moduleName: string, minVisiblePercentage: number) {
        super(
            ModuleVisibleEvent.EventName,
            customEventInit({
                moduleType: moduleType,
                moduleName: moduleName,
                minVisiblePercentage: minVisiblePercentage
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [ModuleVisibleEvent.EventName]: ModuleVisibleEvent;
    }
}
