import bowser from 'bowser';

let instance: DeviceDetection | undefined;

export class DeviceDetection {
    private parser: bowser.Parser.Parser;

    public constructor(userAgent: string) {
        this.parser = bowser.getParser(userAgent);
    }

    public get isIos(): boolean {
        return this.parser.getOSName() === Os.Ios;
    }

    public get isAndroid(): boolean {
        return this.parser.getOSName() === Os.Android;
    }

    public get isFirefox(): boolean {
        return this.parser.getBrowserName() === Browser.Firefox;
    }

    public get isChrome(): boolean {
        return this.parser.getBrowserName() === Browser.Chrome;
    }

    public get isSafari(): boolean {
        return this.parser.getBrowserName() === Browser.Safari;
    }

    public get isEdge(): boolean {
        return this.parser.getBrowserName() === Browser.Edge;
    }

    public get isIe(): boolean {
        return this.parser.getBrowserName() === Browser.IE;
    }

    public get isSmartphoneOrTablet(): boolean {
        const platformType = this.parser.getPlatformType();
        return platformType === Platform.Mobile || platformType === Platform.Tablet;
    }

    public get isSmartphone(): boolean {
        const platformType = this.parser.getPlatformType();
        return platformType === Platform.Mobile;
    }

    public get majorBrowserVersion(): number | null {
        const majorVersion = Number(this.parser.getBrowserVersion()?.split('.')[0]);
        return majorVersion || null;
    }

    public get deviceDetails(): bowser.Parser.ParsedResult {
        return this.parser.getResult();
    }
}

export default instance = instance || (instance = new DeviceDetection(window.navigator.userAgent));

enum Os {
    Ios = 'iOS',
    Windows = 'Windows',
    Android = 'Android'
}

enum Platform {
    Mobile = 'mobile',
    Tablet = 'tablet',
    Desktop = 'desktop'
}

enum Browser {
    Firefox = 'Firefox',
    Chrome = 'Chrome',
    Safari = 'Safari',
    Edge = 'Microsoft Edge',
    IE = 'Internet Explorer'
}
