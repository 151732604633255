import { env } from '@/common/env';

// den här filen behövs för att core-js inkluderar inte dom polyfills
(function (): void {
    polyfillMatches();
    polyFillClosest();
    polyfillCustomEvent();
})();

function polyfillMatches(): void {
    const shouldPolyfill = isElementDefined() && !Element.prototype.matches;
    if (!shouldPolyfill) {
        return;
    }

    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

function polyFillClosest(): void {
    const shouldPolyfill = isElementDefined() && !Element.prototype.closest;
    if (!shouldPolyfill) {
        return;
    }

    Element.prototype.closest = function (s: string) {
        let el: any = this;
        if (!el) {
            return null;
        }

        do {
            if (el.matches(s)) {
                return el;
            }
            el = el.parentElement || el.parentNode;
        } while (!!el && el.nodeType === 1);
        return null;
    };
}

function polyfillCustomEvent(): void {
    if (typeof window === 'object' && typeof window.CustomEvent === 'function') {
        return;
    }

    function CustomEvent(event: any, params: any): CustomEvent<any> {
        params = params || { bubbles: false, cancelable: false, detail: null };
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    if (typeof window === 'object') {
        (window.CustomEvent as any) = CustomEvent;
    } else if (env.isServer()) {
        (global.CustomEvent as any) = CustomEvent;
    }
}

function isElementDefined(): boolean {
    return typeof Element === 'function' || typeof Element === 'object';
}
