import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { ChangeChannelTrackingData, SelectChannelTrackingPayload } from './gtm-events';
import { ChangeAdditionalChannelEvent } from '@/common/custom-events/select-channel/change-additional-channel-event';
import { ChangeLocalChannelEvent } from '@/common/custom-events/select-channel/change-local-channel-event';

export class TrackChangeChannelEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleAdditionalFn = (e: ChangeAdditionalChannelEvent): void => this.handleAdditional(e);
    private handleLocalFn = (e: ChangeLocalChannelEvent): void => this.handleLocal(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(ChangeAdditionalChannelEvent.EventName, this.handleAdditionalFn);
        window.addEventListener(ChangeLocalChannelEvent.EventName, this.handleLocalFn);
    }

    dispose(): void {
        window.removeEventListener(ChangeAdditionalChannelEvent.EventName, this.handleAdditionalFn);
        window.removeEventListener(ChangeLocalChannelEvent.EventName, this.handleLocalFn);
    }

    handleLocal(event: ChangeLocalChannelEvent): void {
        const eventMetadata: SelectChannelTrackingPayload = {
            moduleType: event.detail.eventSource,
            moduleName: 'byt kanal',
            pageType: this.pageMetadataService.pageView?.pageType,
            channelName: undefined,
            channelId: undefined,
            liveChannel: 'p4',
            modulePosition: undefined
        };

        const gtmEvent = new ChangeChannelTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }

    handleAdditional(event: ChangeAdditionalChannelEvent): void {
        const eventMetadata: SelectChannelTrackingPayload = {
            moduleType: event.detail.eventSource,
            moduleName: 'byt kanal',
            pageType: this.pageMetadataService.pageView?.pageType,
            channelName: undefined,
            channelId: undefined,
            liveChannel: 'extra',
            modulePosition: undefined
        };

        const gtmEvent = new ChangeChannelTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
