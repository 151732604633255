






    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiCaption extends Vue {
        @Prop(Boolean) gray?: boolean;
        @Prop(Boolean) white?: boolean;
        @Prop(Boolean) strong?: boolean;
        @Prop(Boolean) inherit?: boolean;
        @Prop({
            default: 'span',
            validator: value => {
                return value.match('span|div|label');
            }
        })
        tag!: string;
    }
