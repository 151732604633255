import deviceDetection from '@/common/device-detection';

async function ie(): Promise<void> {
    if (deviceDetection.isIe) {
        // webpackChunkName comment is needed for readable js filename for hlsjs when importing dynamic modules
        const { default: cssVars } = await import(/* webpackChunkName: "ie" */ 'css-vars-ponyfill');
        cssVars();

        const { default: svg4everybody } = await import(/* webpackChunkName: "ie" */ 'svg4everybody');
        svg4everybody();
    }
}

ie();
