function isDev(): boolean {
    return process.env.NODE_ENV === 'development';
}

function isProd(): boolean {
    return process.env.NODE_ENV === 'production';
}

function isClient(): boolean {
    return process.env.VUE_ENV !== 'server';
}

function isServer(): boolean {
    return process.env.VUE_ENV === 'server';
}

export const env = {
    isDev,
    isProd,
    isClient,
    isServer
};
