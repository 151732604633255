import { mapScheduleMetadataToAudio } from '@/components/mixins/mappers/mapScheduleItemToAudio';
import { AudioKey, AudioMetadata, Branding, Track, AudioType } from '@/components/models';
import { LegacyLivePlayerGetter } from '@/enums/LegacyLivePlayerGetter';
import { PlayerMode } from '@/enums/PlayerMode';
import { AudioGetters, MetadataGetters } from '@/store';
import { PlayerState } from '@/store/PlayerState';
import { RootGetters } from '@/store/RootGetters';
import { Component, Vue } from 'vue-property-decorator';
import { trackMapper } from '@/store/metadata/mappers';
import { AudioMetadataItem } from '@/api/responses/AudioResponse';

@Component
export default class MetadataMixin extends Vue {
    private get liveQueuePositionIndex(): number {
        const now = new Date().getTime();

        return this.$store.getters[LegacyLivePlayerGetter.scheduleItems].findIndex(
            (item: AudioMetadataItem) =>
                item.starttime &&
                item.endtime &&
                new Date(item.starttime).getTime() <= now &&
                now < new Date(item.endtime).getTime()
        );
    }

    protected get queue(): AudioMetadata[] {
        if (this.$store.getters[RootGetters.playerMode] === PlayerMode.Hls) {
            const queue = this.$store.getters[LegacyLivePlayerGetter.scheduleItems].map((o: AudioMetadataItem) =>
                mapScheduleMetadataToAudio(o)
            );

            queue.length = this.liveQueuePositionIndex + 1;

            return queue || [];
        }

        if (this.$store.getters[RootGetters.playerMode] === PlayerMode.Icecast) {
            return [mapScheduleMetadataToAudio(this.$store.getters[LegacyLivePlayerGetter.currentScheduleItem])];
        }

        return this.$store.getters[MetadataGetters.Queue] || [];
    }

    protected get playerMode(): PlayerMode {
        return this.$store.getters[RootGetters.playerMode];
    }

    protected get isLiveMode(): boolean {
        return this.playerMode === PlayerMode.Hls || this.playerMode === PlayerMode.Icecast;
    }

    protected get isIcecastLiveMode(): boolean {
        return this.playerMode === PlayerMode.Icecast;
    }

    protected get isOndemandMode(): boolean {
        return this.$store.getters[RootGetters.isOndemandPlayerMode];
    }

    protected get currentQueuePosition(): number {
        if (this.isOndemandMode) {
            return this.$store.getters[MetadataGetters.CurrentQueuePosition];
        }

        if (this.isLiveMode && this.$store.getters[LegacyLivePlayerGetter.currentTime]) {
            const currentTime = new Date(this.$store.getters[LegacyLivePlayerGetter.currentTime]).getTime();
            return this.queue.findIndex(
                item =>
                    item.startTime &&
                    item.endTime &&
                    currentTime >= item.startTime.getTime() &&
                    currentTime < item.endTime.getTime()
            );
        }

        return 0;
    }

    protected get currentAudioKey(): AudioKey | null {
        if (this.isLiveMode) {
            return {
                id: this.$store.getters[LegacyLivePlayerGetter.id],
                type: this.$store.getters[LegacyLivePlayerGetter.type]
            };
        }
        return this.$store.getters[MetadataGetters.CurrentAudioKey] || null;
    }

    protected get playlistTitle(): string {
        return this.isOndemandMode ? this.$store.getters[MetadataGetters.PlaylistTitle] : '';
    }

    protected get currentAudio(): AudioMetadata {
        if (this.isOndemandMode && this.$store.getters[MetadataGetters.CurrentAudio]) {
            return this.$store.getters[MetadataGetters.CurrentAudio];
        }

        if (this.isLiveMode) {
            const channelId = this.$store.getters[LegacyLivePlayerGetter.id];
            const channelName = this.$store.getters[LegacyLivePlayerGetter.title];
            const programName = this.$store.getters[LegacyLivePlayerGetter.subtitle];
            const programId = this.$store.getters[LegacyLivePlayerGetter.programId];
            const branding = this.$store.getters[LegacyLivePlayerGetter.channelTheme];
            const startTime = this.$store.getters[LegacyLivePlayerGetter.episodeStartTime];
            const endTime = this.$store.getters[LegacyLivePlayerGetter.episodeEndTime];
            const audioType = this.$store.getters[LegacyLivePlayerGetter.type];
            const current = mapScheduleMetadataToAudio(this.$store.getters[LegacyLivePlayerGetter.currentScheduleItem]);

            return {
                src: '',
                id: channelId,
                episodeTitle: '',
                programName,
                programId: programId,
                url: '',
                tracks: [],
                imageUrl: current.imageUrl,
                thumbnailImageUrl: current.thumbnailImageUrl,
                branding,
                date: '',
                channelName,
                startTime,
                endTime,
                duration: undefined,
                isPod: false,
                statistics: {
                    audioType: audioType,
                    audioId: null,
                    publicationId: 0,
                    backendTrackingMetadata: {
                        category: current.statistics?.backendTrackingMetadata?.category
                    }
                }
            };
        }

        return {
            src: '',
            id: 0,
            episodeTitle: '',
            programName: '',
            url: '',
            tracks: [],
            imageUrl: '',
            thumbnailImageUrl: '',
            branding: Branding.Default,
            date: '',
            channelName: '',
            duration: 0,
            isPod: false,
            statistics: {
                audioType: AudioType.Undefined,
                audioId: null,
                publicationId: 0
            }
        };
    }

    protected get hasMoreThanOneAudio(): boolean {
        return this.queue.length > 1;
    }

    protected get hasPreviousAudios(): boolean {
        return this.currentQueuePosition > 0;
    }

    protected get hasNextAudios(): boolean {
        return this.currentQueuePosition < this.queue.length - 1;
    }

    protected get currentTrack(): Track | null {
        if (this.isOndemandMode) {
            const audioPosition = this.$store.getters[AudioGetters.Position];
            return (
                this.currentAudio?.tracks?.find(
                    track => track.starttime && track.endtime && track.starttime < audioPosition && audioPosition < track.endtime
                ) || null
            );
        }

        return this.$store.getters[LegacyLivePlayerGetter.currentTrack]
            ? trackMapper(this.$store.getters[LegacyLivePlayerGetter.currentTrack])
            : null;
    }

    protected get isMetadataLoading(): boolean {
        if (this.isOndemandMode && !this.isLiveMode) {
            return !this.$store.getters[MetadataGetters.CurrentAudio];
        }

        return this.$store.getters[LegacyLivePlayerGetter.state] === PlayerState.Loading;
    }

    $store: any;
}
