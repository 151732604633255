import { customEventInit } from './custom-events-common';

interface ShowAllClickedEventPayload {
    moduleType: string;
    moduleName: string;
    moduleHeading: string;
}

export class ShowAllClickedEvent extends CustomEvent<ShowAllClickedEventPayload> {
    public static EventName = 'show-all-clicked' as const;

    constructor(moduleType: string, moduleName: string, moduleHeading: string) {
        super(
            ShowAllClickedEvent.EventName,
            customEventInit({
                moduleType: moduleType,
                moduleName: moduleName,
                moduleHeading: moduleHeading
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [ShowAllClickedEvent.EventName]: ShowAllClickedEvent;
    }
}
