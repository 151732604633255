import { customEventInit } from '@/common/custom-events/custom-events-common';

type ChangeChannelEventSource = 'global-meny' | 'karusell' | 'senaste-nyheterna';

type ChangeAdditionalChannelEventPayload = {
    eventSource: ChangeChannelEventSource;
    redirect: boolean;
};

export class ChangeAdditionalChannelEvent extends CustomEvent<ChangeAdditionalChannelEventPayload> {
    public static EventName = 'change-additional-channel' as const;

    constructor(eventSource: ChangeChannelEventSource, shouldRedirect: boolean = false) {
        super(
            ChangeAdditionalChannelEvent.EventName,
            customEventInit({
                eventSource: eventSource,
                redirect: shouldRedirect
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [ChangeAdditionalChannelEvent.EventName]: ChangeAdditionalChannelEvent;
    }
}
