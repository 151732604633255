import { throttledErrorLogger } from '@/logging/ThrottledErrorLogger';
import { SpaSelector } from '@/spa-navigation/spa-selectors';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageViewMetadata } from '@/tracking/gtm/tag-manager-types';
import { TrackingMetadata } from '@/tracking/track-types';
import { PageChangedEvent } from './custom-events/page-changed-event';
import { PageRenderedEvent } from './custom-events/page-rendered-event';

export class PageMetadataService implements DisposableEventListener {
    private handleFn = (e: PageChangedEvent | PageRenderedEvent): void => this.handle(e); // to be able to call removeEventListener

    private _pageView?: PageViewMetadata = undefined;
    private _tracking?: TrackingMetadata = undefined;

    get pageView(): PageViewMetadata | undefined {
        return this._pageView;
    }

    get tracking(): TrackingMetadata | undefined {
        return this._tracking;
    }

    public init(): void {
        window.addEventListener(PageChangedEvent.EventName, this.handleFn);
        window.addEventListener(PageRenderedEvent.EventName, this.handleFn);
    }

    public dispose(): void {
        window.removeEventListener(PageChangedEvent.EventName, this.handleFn);
        window.removeEventListener(PageRenderedEvent.EventName, this.handleFn);
    }

    private handle(event: PageChangedEvent | PageRenderedEvent): void {
        const pageDocument = event.detail.document;

        if (!event.detail.document) {
            return;
        }

        const trackingMetadataStr = pageDocument.querySelector(SpaSelector.metadata)?.innerHTML;
        const pageViewMetadataStr = pageDocument.querySelector(SpaSelector.gtmMetadata)?.innerHTML;

        this._pageView = this.tryJsonParse<PageViewMetadata>(pageViewMetadataStr);
        this._tracking = this.tryJsonParse<TrackingMetadata>(trackingMetadataStr);
    }

    private tryJsonParse<T>(str: string | undefined | null): T | undefined {
        if (str) {
            try {
                return JSON.parse(str);
            } catch (error) {
                throttledErrorLogger.logError({ message: 'Unable to JSON parse page metadata', error: error });
            }
        }

        return undefined;
    }
}

export const pageMetadataService = new PageMetadataService();
