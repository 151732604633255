export enum AudioType {
    Episode = 'episode',
    Clip = 'clip',
    Publication = 'publication',
    Channel = 'channel',
    Extra = 'extra',
    Music = 'music',
    Secondary = 'secondary',
    Undefined = 'undefined'
}
