import { CookieSettings } from '@/common/storage';
import { customEventInit } from './custom-events-common';

export type CookieConsentUpdatedEventPayload = { currentCookieSettings: CookieSettings; previousCookieSettings: CookieSettings };

export class CookieConsentUpdatedEvent extends CustomEvent<CookieConsentUpdatedEventPayload> {
    public static EventName = 'cookie-consent-updated' as const;
    constructor(currentCookieSettings: CookieSettings, previousCookieSettings: CookieSettings) {
        super(
            CookieConsentUpdatedEvent.EventName,
            customEventInit({ currentCookieSettings: currentCookieSettings, previousCookieSettings: previousCookieSettings })
        );
    }
}

declare global {
    interface WindowEventMap {
        [CookieConsentUpdatedEvent.EventName]: CookieConsentUpdatedEvent;
    }
}
