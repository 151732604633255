import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { CategoryButtonClickedEvent } from '@/common/custom-events/category-button-clicked-event';
import { CategoryButtonClickedTrackingData, CategoryButtonClickedTrackingPayload } from './gtm-events';

export class TrackCategoryButtonClickedEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleFn = (e: CategoryButtonClickedEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(CategoryButtonClickedEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(CategoryButtonClickedEvent.EventName, this.handleFn);
    }

    handle(event: CategoryButtonClickedEvent): void {
        const payload = event.detail;

        if (!payload) {
            return;
        }

        const eventMetadata: CategoryButtonClickedTrackingPayload = {
            pageType: this.pageMetadataService.pageView?.pageType,
            pageTitle: this.pageMetadataService.pageView?.pageTitle,
            linkText: payload.linkText,
            linkUrl: payload.url
        };

        const gtmEvent = new CategoryButtonClickedTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
