export enum AudioEvents {
    TimeUpdate = 'timeupdate',
    DurationChange = 'durationchange',
    Progress = 'progress',
    Error = 'error',
    Play = 'play',
    Playing = 'playing',
    Pause = 'pause',
    Ended = 'ended',
    VolumeChange = 'volumechange',
    Seeking = 'seeking',
    LoadStart = 'loadstart',
    Startup = 'startup',
    Stalled = 'stalled',
    CanPlay = 'canplay'
}
