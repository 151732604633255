export enum PlayerTrackingAction {
    CATCHUP_FASTFORWARD_15_SEC = 'spelaren_catchup_fram15sek',
    CATCHUP_RESTART = 'spelaren_catchup_tillstart',
    CATCHUP_REWIND_15_SEC = 'spelaren_catchup_bak15sek',
    CATCHUP_SCRUB = 'spelaren_catchup_scrub',
    FOLD_DOWN = 'spelaren_nedfallning',
    LISTENED_5_SEC = 'lyssnat5sek',
    LISTENED_10_SEC = 'lyssnat10sek',
    LISTENED_30_SEC = 'lyssnat30sek',
    LISTENED_60_SEC = 'lyssnat60sek',
    LISTENING = 'lyssnar',
    PLAY = 'spela',
    SOUND_QUALITY = 'spelaren_ljudkvalitet'
}

export interface TrackingMetadata {
    articleTextLength?: string;
    articleTitle?: string;
    autoplay?: string;
    autoplayEnabled?: string;
    broadcastId?: string;
    broadcastName?: string;
    category?: string;
    channelId?: string;
    channelName?: string;
    clipId?: string;
    clipName?: string;
    collectionName?: string;
    daysSincePublish?: string;
    episodeId?: string;
    episodeName?: string;
    hasPriorityGroup?: string;
    hasRelatedArticles?: string;
    programId?: string;
    programName?: string;
    publishDate?: string;
    searchTrackingContext?: string;
    startedFromStarttime?: string;
    storyId?: string;
}
