




    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class LazyImage extends Vue {
        @Prop(String) src!: string;
        @Prop(String) alt!: string;
    }
