import { TrackingMetadata } from '@/tracking/track-types';
import { customEventInit } from './custom-events-common';

type ShareButtonClickedPayload = {
    url: string;
    trackingMetadata: TrackingMetadata;
};

export class ShareButtonClickedEvent extends CustomEvent<ShareButtonClickedPayload> {
    public static EventName = 'share-button-clicked' as const;
    constructor(url: string, trackingMetadata: TrackingMetadata) {
        super(ShareButtonClickedEvent.EventName, customEventInit({ url: url, trackingMetadata: trackingMetadata }));
    }
}

declare global {
    interface WindowEventMap {
        [ShareButtonClickedEvent.EventName]: ShareButtonClickedEvent;
    }
}
