import storage from '@/common/storage';
import playerMessages from '@/enums/player-messages';
import playerStates from '@/enums/player-states';
import playerHlsToggler from '@/common/player-hls-toggler';
import { playerLogger } from '@/logging/PlayerErrorLogger';
import playerApi from '@/api/player-api';
import { logger } from '@/api/log-api';
import { checkGeoblock } from '@/store/check-geoblock';
import { PlayerTrackingAction } from '@/tracking/track-types';
import { gtmPlayerTracker } from '@/tracking/gtm/gtm-player-tracker';
import { ListenLiveDurationTrackerEventListener } from '@/tracking/ListenLiveDurationTrackerEventListener';

var AUDIO_AVAILABLE = 0;
var AUDIO_EXPIRED = 100;
var AUDIO_NOT_FOUND = 101;
var FIVE_SECOND_THRESHOLD = 5;
var TEN_SECOND_THRESHOLD = 10;
var THIRTY_SECOND_THRESHOLD = 30;
var SIXTY_SECOND_THRESHOLD = 60;
var LIVE_SPAN_SECONDS = 5;
var LIVE_SPAN_MILLISECONDS = LIVE_SPAN_SECONDS * 1000;
var RESTORE_IDLE_TIME_MILLISECONDS = 15 * 60 * 1000;
var PROGRESS_TICKER_MILLISECONDS = 1000;

var LIVE_BUFFER_SECONDS = 0;
var AUDIO_DELAY_SECONDS = 0;

var audioElement = typeof Audio === 'function' ? new Audio() : null;
var hls;
var progressTicker;

var lastTracked = 0;
var hasTracked5Seconds = false;
var hasTracked10Seconds = false;
var hasTracked30Seconds = false;
var hasTracked60Seconds = false;
var completedEpisodes = {};
var accumulatedListenedTime = 0;
var lastTickTime = null;
var seekToLiveOnReload = false;
let listenLiveDurationEventListener;

export default {
    namespaced: true,
    state: {
        isLive: false,
        isActive: false,
        playerState: playerStates.PAUSED,
        statusMessage: '',
        streamPositionTime: null,
        streamStartTime: new Date(),
        streamOffset: 0,
        streamTotalDuration: 0,
        livePositionTime: null,
        audioDelayMilliseconds: AUDIO_DELAY_SECONDS * 1000,
        playerMessage: ''
    },
    getters: {
        id: function (state, getters, rootState) {
            return rootState.player.live.id;
        },
        type: function (state, getters, rootState) {
            return rootState.player.live.type;
        },
        publicationId: function (state, getters, rootState) {
            return rootState.player.live.publicationId;
        },
        currentTime: function (state, getters, rootState, rootGetters) {
            return rootGetters['player/live/currentTime'];
        },
        itemCount: function (state, getters, rootState) {
            return rootState.player.live.scheduleItems.length;
        },
        streamPosition: function (state) {
            return state.streamPositionTime ? (state.streamPositionTime.getTime() - state.streamStartTime.getTime()) / 1000 : 0;
        },
        isStreamPositionLive: function (state) {
            if (!state.streamPositionTime) {
                return true;
            }

            if (state.livePositionTime) {
                var streamPositionTimeWithLiveSpan = new Date(
                    state.streamPositionTime.getTime() + LIVE_SPAN_MILLISECONDS
                ).getTime();
                return streamPositionTimeWithLiveSpan >= state.livePositionTime.getTime();
            }
            return false;
        },
        livePosition: function (state) {
            return state.livePositionTime ? (state.livePositionTime.getTime() - state.streamStartTime.getTime()) / 1000 : 0;
        },
        experiment: function (state, getters, rootState, rootGetters) {
            return rootGetters['player/live/experiment'];
        },
        episodeStartTime: function (state, getters, rootState, rootGetters) {
            return rootGetters['player/live/episodeStartTime'];
        },
        episodeEndTime: function (state, getters, rootState, rootGetters) {
            return rootGetters['player/live/episodeEndTime'];
        },
        previousEpisodeStartTime: function (state, getters, rootState, rootGetters) {
            return rootGetters['player/live/previousEpisodeStartTime'];
        },
        quality: function (state, getters, rootState) {
            return rootState.player.quality;
        },
        channelName: function (state, getters, rootState) {
            return rootState.player.live.channelTheme;
        },
        channelId: function (state, getters, rootState) {
            return rootState.player.live.id;
        },
        trackContext: function (state, getters, rootState) {
            return rootState.player.trackContext;
        },
        scheduleStartPosition: function (state, getters, rootState) {
            var firstScheduleItem = rootState.player.live.scheduleItems[0];
            if (!firstScheduleItem) {
                return null;
            }
            return timeToPosition(state.streamStartTime, new Date(firstScheduleItem.starttime));
        },
        lowerBoundPosition: function (state, getters) {
            if (getters.scheduleStartPosition && getters.scheduleStartPosition > state.streamOffset) {
                return getters.scheduleStartPosition + AUDIO_DELAY_SECONDS;
            }
            return state.streamOffset + AUDIO_DELAY_SECONDS;
        },
        currentEpisode: function (state, getters, rootState, rootGetters) {
            return rootGetters['player/live/currentScheduleItem'];
        },
        eightyPercentOfCurrentEpisodeTime: function (state, getters) {
            if (!getters.currentEpisode) {
                return undefined;
            }

            var startTime = new Date(getters.currentEpisode.starttime);
            var endTime = new Date(getters.currentEpisode.endtime);
            var length = endTime.getTime() - startTime.getTime();
            var eightyPercent = length * 0.8;
            return new Date(startTime.getTime() + eightyPercent);
        },
        clientIdHash: function (state, getters, rootState, rootGetters) {
            return rootGetters['player/live/clientIdHash'];
        },
        isLive: state => state.isLive,
        isActive: state => state.isActive
    },
    actions: {
        init: async function (context) {
            if (await useHlsJs()) {
                // webpackChunkName comment is needed for readable js filename for hlsjs when importing dynamic modules
                const { default: hlsJs } = await import(/* webpackChunkName: "hlsjs" */ 'hls.js');

                hls = new hlsJs({
                    maxMaxBufferLength: 120,
                    levelLoadingMaxRetry: 6,
                    levelLoadingMaxRetryTimeout: 10000,
                    debug: false
                });

                addHlsJsEventListeners(context, hls, hlsJs);
            } else {
                context.dispatch('addHlsNativeEventListeners');
            }
            context.dispatch('addGeneralEventListeners');
        },
        addHlsNativeEventListeners: function (context) {
            audioElement.addEventListener('timeupdate', function () {
                if (!audioElementIsPlaying() || audioElement?.seekable.length === 0) {
                    return;
                }

                let seekableStartTime = audioElement.seekable.start(0);
                var streamOffset = seekableStartTime < 0 ? 0 : seekableStartTime;
                var streamTotalDuration = audioElement.seekable.end(0);
                var seekableLength = streamTotalDuration - streamOffset;

                if (context.state.streamTotalDuration === 0) {
                    var streamStartTime = new Date(new Date().getTime() - seekableLength * 1000 - streamOffset * 1000);
                    context.commit('setStreamStartTime', streamStartTime);
                }

                context.commit('setStreamOffset', streamOffset);
                context.commit('setStreamTotalDuration', streamTotalDuration);

                onMediaElementTimeUpdate(context);
            });
            audioElement.addEventListener('error', function () {
                context.dispatch('handleAudioError');
            });
        },
        addListenLiveEventListeners: function (context, audioSrc) {
            var time = context.state.streamPositionTime ? context.state.streamPositionTime : context.state.livePositionTime;
            listenLiveDurationEventListener = new ListenLiveDurationTrackerEventListener(
                audioElement,
                audioSrc,
                time,
                context.getters.clientIdHash
            );
        },
        addGeneralEventListeners: function (context) {
            audioElement.addEventListener('volumechange', function () {
                context.dispatch('player/setVolume', audioElement.volume * 100, { root: true });
            });
            audioElement.addEventListener('play', function () {
                context.dispatch('setPlayingState');
            });
            audioElement.addEventListener('playing', function () {
                context.dispatch('setPlayingState');
            });
        },
        updatePlayingStateFromTimeUpdate: function (context) {
            if (context.state.playerState !== playerStates.PLAYING && audioElementIsPlaying())
                context.dispatch('setPlayingState');
            else if (context.state.playerState === playerStates.PLAYING && audioElement.paused)
                context.dispatch('setPausedState');
        },
        async play(context, audio) {
            context.commit('setIsActive', true);
            resetAudioState(context);
            context.dispatch('setLoadingState');

            try {
                context.commit('setPlayerMessage');
                const response = await loadAudioAsync(context, audio.id, audio.type, context.getters.quality);

                if (response.data.state === AUDIO_AVAILABLE) {
                    await setAudioElementSrcAndFormatAsync(response.data.audioUrl);

                    disposeListenLiveEventListeners();
                    await audioElement.play();
                    setListenLiveEventListeners(context, response.data.audioUrl);
                    listenLiveDurationEventListener.handleEvent({ type: 'startup' });
                    context.commit('setIsLive', true);

                    if (response.data.isGeoblockEnabled) {
                        await checkGeoblock(response.data.audioUrl, context);
                    }
                } else if (response.data.state === AUDIO_EXPIRED) {
                    context.dispatch('setErrorState', playerMessages.AUDIO_EXPIRED);
                } else if (response.data.state === AUDIO_NOT_FOUND) {
                    context.dispatch('setErrorState', playerMessages.AUDIO_NOT_FOUND);
                }
            } catch (error) {
                await setAudioElementSrcAndFormatAsync(null);
                audioElement.pause();
                context.dispatch('handleUrlError', error);
            }
        },
        async onQualityChangeAsync(context) {
            var startAtTime = context.state.streamPositionTime;
            const isLive = context.getters.isStreamPositionLive;
            if (!context.state.isActive) {
                return;
            }

            const result = await loadAudioAsync(context, context.getters.id, context.getters.type, context.getters.quality);

            await setAudioElementSrcAndFormatAsync(result.data.audioUrl);

            if (!isLive) {
                context.dispatch('seekTime', startAtTime);
            }

            await audioElement.play();
        },
        restoreState: function (context) {
            context.commit('setIsActive', true);
            setLivePosition(context);
            context.commit('setIsLive', true);
            const lastActive = storage.lastActive;
            if (!isBeyondRestoreIdleTime(lastActive)) {
                hasTracked5Seconds = storage.accumulatedListenedTime > FIVE_SECOND_THRESHOLD;
            }
        },
        pause: async function (context) {
            if (context.state.playerState === playerStates.PLAYING) {
                trackPlayerListeningEvent(context);
            }
            context.commit('setIsLive', false);
            context.dispatch('setPausedState');
            context.dispatch('saveCurrentTimeToStorage');
            audioElement.pause();
            if ((await useHlsJs()) && hls) {
                hls.stopLoad();
            }
        },
        resume: async function (context) {
            if (context.state.isError || !audioElement.src) {
                context.dispatch('player/play', { id: context.getters.id, type: context.getters.type }, { root: true });
            } else {
                if ((await useHlsJs()) && hls) {
                    hls.startLoad(context.getters.streamPosition);
                }
                context.dispatch('setLoadingState');
                setLastTickTime(null);
                context.dispatch('seekTime', context.state.streamPositionTime);
                audioElement.play();
            }
        },
        rewind: function (context) {
            context.dispatch('seekPosition', context.getters.streamPosition - 15);
        },
        fastforward: function (context) {
            context.dispatch('seekPosition', context.getters.streamPosition + 15);
        },
        seekToEpisodeStart: function (context) {
            context.dispatch('seekToScheduleTime', context.getters.episodeStartTime);
        },
        seekToPreviousEpisodeStart: function (context) {
            context.dispatch('seekToScheduleTime', context.getters.previousEpisodeStartTime);
        },
        seekToLive: function (context) {
            context.dispatch('seekPosition', context.getters.livePosition);
        },
        seekPosition: function (context, position) {
            let seekPosition;
            if (position < context.getters.lowerBoundPosition) {
                seekPosition = context.getters.lowerBoundPosition;
            } else if (position + LIVE_SPAN_SECONDS > context.getters.livePosition) {
                seekPosition = context.getters.livePosition - LIVE_SPAN_SECONDS;
            } else {
                seekPosition = position;
            }
            audioElement.currentTime = seekPosition;
            context.dispatch('moveToPosition', seekPosition);
        },
        seekPercentage: function (context, percentageWithinEpisode) {
            var episodeDurationSeconds =
                (context.getters.episodeEndTime.getTime() - context.getters.episodeStartTime.getTime()) / 1000;
            var streamStartTimeAsRealTime = convertToRealTime(
                context.state.streamStartTime,
                context.state.audioDelayMilliseconds
            );
            var episodeStartPosition = (context.getters.episodeStartTime.getTime() - streamStartTimeAsRealTime.getTime()) / 1000;

            var seekPositionWithinEpisode = (episodeDurationSeconds * percentageWithinEpisode) / 100;
            var seekPosition = episodeStartPosition + seekPositionWithinEpisode;
            context.dispatch('seekPosition', seekPosition);
        },
        moveToPosition: function (context, position) {
            if (context.state.playerState === playerStates.PLAYING) {
                context.dispatch('setLoadingState');
            }
            context.dispatch('setStreamPositionTime', position);
            context.commit('setIsLive', context.getters.isStreamPositionLive);
        },
        seekTime: function (context, time) {
            var position = timeToPosition(context.state.streamStartTime, time);
            context.dispatch('seekPosition', position);
        },
        seekToScheduleTime: function (context, scheduleTime) {
            var streamTime = new Date(scheduleTime.getTime() + context.state.audioDelayMilliseconds);
            context.dispatch('seekTime', streamTime);
        },
        handleAudioError: function (context, errorData) {
            var errorMessage = getAudioErrorMessage(errorData?.details);
            context.dispatch('setErrorState', errorMessage);
            playerLogger.logHlsError(errorData?.url, `type: ${errorData?.type}, details: ${errorData?.details}`);
        },
        handleUrlError: function (context, error) {
            const statusCode = error.request ? error.request.status : 0;
            const url = error.request ? error.request.responseURL : '';
            if (statusCode !== 404) {
                playerLogger.logHlsError(url, `http status code: ${statusCode}`);
            }
        },
        setPlayingState: function (context) {
            context.commit('setPlayerState', playerStates.PLAYING);
            context.commit('setStatusMessage');
        },
        setPausedState: function (context) {
            context.commit('setPlayerState', playerStates.PAUSED);
            context.commit('setStatusMessage');
        },
        setLoadingState: function (context) {
            context.commit('setPlayerState', playerStates.LOADING);
            context.commit('setStatusMessage', playerMessages.BUFFERING);
        },
        setErrorState: function (context, errorMessage) {
            context.commit('setPlayerState', playerStates.ERROR);
            context.commit('setStatusMessage', errorMessage);
        },
        setReconnectingState: function (context) {
            context.commit('setPlayerState', playerStates.RECONNECTING);
            context.commit('setStatusMessage', playerMessages.RECONNECTING);
        },
        deactivate: function (context) {
            context.commit('setIsActive', false);
            context.dispatch('pause');
            resetAudioState(context);
        },
        unload: function (context) {
            if (context.state.playerState === playerStates.PLAYING) {
                trackPlayerListeningEvent(context);
                return;
            }
        },
        setVolume: function (context, volume) {
            audioElement.volume = volume / 100;
        },
        saveCurrentTimeToStorage: function (context) {
            var streamPositionTime =
                context.state.playerState === playerStates.PLAYING && context.state.isLive
                    ? null
                    : context.state.streamPositionTime;

            storage.streamPositionTime = streamPositionTime;
            storage.lastActive = new Date();
            storage.lastTracked = lastTracked;
            storage.accumulatedListenedTime = accumulatedListenedTime;
        },
        tickLiveTime: function (context) {
            if (context.state.livePositionTime) {
                var livePositionTime = new Date(context.state.livePositionTime.getTime() + PROGRESS_TICKER_MILLISECONDS);
                context.commit('setLivePositionTime', livePositionTime);
            }
        },
        trackPlay: function (context) {
            gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.PLAY);
        },
        setStreamPositionTime: function (context, streamPosition) {
            var streamPositionInMilliseconds = streamPosition * 1000;
            var streamPositionTime = new Date(context.state.streamStartTime.getTime() + streamPositionInMilliseconds);
            context.commit('setStreamPositionTime', streamPositionTime);
        }
    },
    mutations: {
        setIsLive: function (state, isLive) {
            state.isLive = isLive;
        },
        setIsActive: function (state, isActive) {
            state.isActive = isActive;
        },
        setPlayerState: function (state, playerState) {
            state.playerState = playerState;
        },
        setStatusMessage: function (state, statusMessage) {
            state.statusMessage = statusMessage || '';
        },
        setPlayerMessage: function (state, playerMessage) {
            state.playerMessage = playerMessage || '';
        },
        setQuality: function (state, quality) {
            state.quality = quality;
        },
        setStreamPositionTime: function (state, streamPositionTime) {
            state.streamPositionTime = streamPositionTime;
        },
        setStreamStartTime: function (state, streamStartTime) {
            state.streamStartTime = streamStartTime;
        },
        setStreamOffset: function (state, streamOffset) {
            state.streamOffset = streamOffset;
        },
        setStreamTotalDuration: function (state, duration) {
            state.streamTotalDuration = duration;
        },
        setLivePositionTime: function (state, livePositionTime) {
            state.livePositionTime = livePositionTime;
        }
    }
};

function onMediaElementTimeUpdate(context) {
    context.dispatch('setStreamPositionTime', audioElement.currentTime);
    context.dispatch('saveCurrentTimeToStorage');

    if (context.state.livePositionTime === null) {
        setLivePosition(context);
    }

    if (seekToLiveOnReload) {
        context.dispatch('seekToLive');
        context.commit('setIsLive', true);
        seekToLiveOnReload = false;
    }

    context.dispatch('updatePlayingStateFromTimeUpdate');

    if (audioElementIsPlaying()) {
        var dateNow = new Date();
        setAccumulatedListenedTime(dateNow);
        setLastTickTime(dateNow);
        trackListenedTime(context);
    }
}

function setLivePosition(context) {
    if (context.state.streamTotalDuration) {
        var livePositionInMilliseconds =
            (context.state.streamTotalDuration + context.state.streamOffset - LIVE_BUFFER_SECONDS) * 1000;
        context.commit('setLivePositionTime', new Date(context.state.streamStartTime.getTime() + livePositionInMilliseconds));
    } else {
        context.commit('setLivePositionTime', new Date());
    }

    progressTicker = setInterval(function () {
        context.dispatch('tickLiveTime');
    }, PROGRESS_TICKER_MILLISECONDS);
}

async function resetHls(context) {
    hls.destroy();
    await context.dispatch('init');
    hls.attachMedia(audioElement);
}

async function loadAudioAsync(context, id, type, quality) {
    if ((await useHlsJs()) && hls) {
        await resetHls(context);
    } else {
        audioElement.load();
    }

    const options = getAudioUrlOptions(id, type, quality);
    return playerApi.getAudioUrl(options);
}

function getAudioUrlOptions(id, type, quality) {
    return {
        id: id,
        type: type,
        quality: quality,
        format: 'hls'
    };
}

async function setAudioElementSrcAndFormatAsync(src) {
    if ((await useHlsJs()) && src) {
        hls.loadSource(src);
    } else {
        if (src) {
            audioElement.src = src;
        } else {
            audioElement.removeAttribute('src');
        }
    }
}

async function useHlsJs() {
    return !playerHlsToggler.hasNativeHlsSupport() && (await playerHlsToggler.hasHlsSupportAsync());
}

function isBeyondRestoreIdleTime(lastActive) {
    return lastActive ? new Date().getTime() - new Date(lastActive).getTime() > RESTORE_IDLE_TIME_MILLISECONDS : false;
}

function getAudioErrorMessage(errorCode) {
    return playerMessages.PLAYBACK_ERROR + ' (FELKOD: ' + errorCode + ')';
}

function trackListenedTime(context) {
    var threshold = getNextGaThreshold();

    if (!hasTracked5Seconds && accumulatedListenedTime > FIVE_SECOND_THRESHOLD) {
        gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.LISTENED_5_SEC);
        hasTracked5Seconds = true;
    }

    if (!hasTracked10Seconds && accumulatedListenedTime > TEN_SECOND_THRESHOLD) {
        gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.LISTENED_10_SEC);
        hasTracked10Seconds = true;
    }
    if (!hasTracked30Seconds && accumulatedListenedTime > THIRTY_SECOND_THRESHOLD) {
        gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.LISTENED_30_SEC);
        hasTracked30Seconds = true;
    }

    if (!hasTracked60Seconds && accumulatedListenedTime > SIXTY_SECOND_THRESHOLD) {
        gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.LISTENED_60_SEC);
        hasTracked60Seconds = true;
    }

    if (accumulatedListenedTime > threshold && lastTracked < threshold) {
        trackPlayerListeningEvent(context);
    }

    if (!completedEpisodes[context.getters.currentEpisode.starttime]) {
        var completedEpisodeMark = context.getters.eightyPercentOfCurrentEpisodeTime;
        var completedEpisodeThreshold = new Date(completedEpisodeMark.getTime() + 5 * 1000);
        var adjustedStreamTime = new Date(context.state.streamPositionTime.getTime() - context.state.audioDelayMilliseconds);
        var hasCompleted = adjustedStreamTime > completedEpisodeMark && adjustedStreamTime < completedEpisodeThreshold;

        if (hasCompleted) {
            completedEpisodes[context.getters.currentEpisode.starttime] = true;
        }
    }
}

function trackPlayerListeningEvent(context) {
    var trackValue = Math.round(accumulatedListenedTime - lastTracked);
    const oneWeek = 604800;

    if (trackValue < 1) {
        return;
    }

    if (trackValue > oneWeek) {
        logger.logDebug({
            message: 'Heartbeat too large, hls',
            source: 'hls-store.js',
            url: audioElement.src,
            data: {
                trackValue: trackValue,
                lastTracked: lastTracked,
                audioPosition: storage.audioPosition,
                playerLastTracked: storage.lastTracked,
                accumulatedListenedTime: storage.accumulatedListenedTime,
                audioElCurrentTime: audioElement.currentTime,
                audioElSrc: audioElement.src
            }
        });

        lastTracked = accumulatedListenedTime;
        return;
    }
    gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.LISTENING, trackValue);
    lastTracked = accumulatedListenedTime;
}

function getNextGaThreshold() {
    if (lastTracked < 300) {
        return Math.floor(lastTracked / 60) * 60 + 60;
    }
    return lastTracked + 300;
}

function setLastTickTime(dateTime) {
    lastTickTime = dateTime;
}

function setAccumulatedListenedTime(dateNow) {
    if (lastTickTime) {
        accumulatedListenedTime = (dateNow.getTime() - lastTickTime.getTime()) / 1000 + accumulatedListenedTime;
    }
}

function resetAudioState(context) {
    lastTracked = 0;
    hasTracked5Seconds = false;
    //Behövas 10,30 nollställas?
    hasTracked10Seconds = false;
    hasTracked30Seconds = false;
    accumulatedListenedTime = 0;
    completedEpisodes = {};
    context.commit('setLivePositionTime', null);
    context.commit('setStreamTotalDuration', 0);
    context.commit('setStreamStartTime', new Date());
    context.commit('setStreamOffset', 0);
    clearInterval(progressTicker);
    setLastTickTime(null);
}

function audioElementIsPlaying() {
    return (
        audioElement && audioElement.currentTime > 0 && !audioElement.paused && !audioElement.ended && audioElement.readyState > 2
    );
}

function convertToRealTime(dateTime, streamDelayMilliseconds) {
    return new Date(dateTime.getTime() - streamDelayMilliseconds);
}

function timeToPosition(streamStartTime, time) {
    return Math.ceil((time.getTime() - streamStartTime.getTime()) / 1000);
}

function addHlsJsEventListeners(context, hlsInstance, hlsModule) {
    audioElement.addEventListener('timeupdate', function () {
        onMediaElementTimeUpdate(context);
    });

    hlsInstance.on(hlsModule.Events.LEVEL_UPDATED, function (event, data) {
        var streamOffset = data.details.fragments[0].start;

        if (context.state.streamTotalDuration === 0) {
            var streamStartTime = new Date(data.details.fragments[0].programDateTime - streamOffset * 1000);
            context.commit('setStreamStartTime', streamStartTime);
        }

        context.commit('setStreamOffset', streamOffset);
        context.commit('setStreamTotalDuration', data.details.totalduration);
    });

    hlsInstance.on(hlsModule.Events.ERROR, function (event, data) {
        if (data.type === hlsModule.ErrorTypes.NETWORK_ERROR && data.fatal) {
            context.dispatch('handleAudioError', { url: data.url, type: data.type, details: data.details });
        } else if (data.type === hlsModule.ErrorTypes.NETWORK_ERROR) {
            context.dispatch('setReconnectingState');
        }
    });
}

function disposeListenLiveEventListeners() {
    if (listenLiveDurationEventListener) {
        listenLiveDurationEventListener.dispose();
    }
}

function setListenLiveEventListeners(context, audioSrc) {
    context.dispatch('addListenLiveEventListeners', audioSrc);
}
