




    import { Vue, Component, Prop } from 'vue-property-decorator';

    @Component
    export default class UiLinkInternal extends Vue {
        @Prop(String) readonly title: string | undefined;
        @Prop(String) readonly url: string | undefined;
        @Prop(Boolean) readonly capitalize: boolean | undefined;
        @Prop(Boolean) readonly inverted: boolean | undefined;

        onClick(): void {
            this.$emit('click');
        }
    }
