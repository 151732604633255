export enum AudioPlayerAction {
    AudioStart = 'spela',
    ContinuePlay = 'fortsätt_spela',
    Listened5Seconds = 'lyssnat5sek',
    Listened10Seconds = 'lyssnat10sek',
    Listened30Seconds = 'lyssnat30sek',
    Listened60Seconds = 'lyssnat60sek',
    Listening = 'lyssnar',
    ListenedThrough = 'avlyssnat',
    ToDirect = 'spelaren_catchup_tilldirekt',
    SoundQualityChange = 'spelaren_ljudkvalitet',
    VolumeChange = 'spelaren_volym',
    Title = 'spelaren_titel',
    CatchupTimeout = 'catchuptimeout'
}
