export enum LegacyLivePlayerAction {
    RestoreFromStorage = 'player/live/restoreState',
    Pause = 'player/live/pause',
    Play = 'player/live/play',
    Deactivate = 'player/live/deactivate',
    Resume = 'player/live/resume',
    LiveSeekToLive = 'player/live/hls/seekToLive',
    LiveSeekTime = 'player/live/hls/seekTime',
    LiveSeekPercentage = 'player/live/hls/seekPercentage',
    LivePlay = 'player/live/play',
    LiveHlsRewind = 'player/live/hls/rewind',
    LiveHlsFastforward = 'player/live/hls/fastforward',
    Init = 'player/live/init',
    Unload = 'player/live/unload',
    SetVolume = 'player/live/setVolume',
    OnQualityChangeAsync = 'player/live/onQualityChangeAsync'
}
