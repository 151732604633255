import { customEventInit } from './custom-events-common';

export type ModuleStartedEventSource = 'image' | 'header' | 'play-button';

interface ModuleStartedEventPayload {
    eventSource: ModuleStartedEventSource;
    moduleName: string;
    title: string;
}

export class ModuleStartedEvent extends CustomEvent<ModuleStartedEventPayload> {
    public static EventName = 'module-started' as const;

    constructor(eventSource: ModuleStartedEventSource, moduleName: string, title: string) {
        super(
            ModuleStartedEvent.EventName,
            customEventInit({
                eventSource: eventSource,
                moduleName: moduleName,
                title: title
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [ModuleStartedEvent.EventName]: ModuleStartedEvent;
    }
}
