import { customEventInit } from './custom-events-common';

type PageChangedEventPayload = {
    document: Document;
};

export class PageChangedEvent extends CustomEvent<PageChangedEventPayload> {
    public static EventName = 'page-changed' as const;

    constructor(document: Document) {
        super(
            PageChangedEvent.EventName,
            customEventInit({
                document: document
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [PageChangedEvent.EventName]: PageChangedEvent;
    }
}
