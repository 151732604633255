import { render, staticRenderFns } from "./Progress.vue?vue&type=template&id=2b8d5ede&scoped=true&"
import script from "./Progress.vue?vue&type=script&lang=ts&"
export * from "./Progress.vue?vue&type=script&lang=ts&"
import style0 from "./Progress.vue?vue&type=style&index=0&id=2b8d5ede&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8d5ede",
  null
  
)

export default component.exports