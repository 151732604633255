import { LogData } from '@/logging/LogTypes';

export class SrError extends Error {
    public data: LogData;

    constructor(data: LogData) {
        super(data.message);
        this.data = data;
    }
}
