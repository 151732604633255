import dayJs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import sv from 'dayjs/locale/sv';
import calendar from 'dayjs/plugin/calendar';

dayJs.extend(utc);
dayJs.extend(calendar);
dayJs.locale(sv);

export default {
    isoShort(dateString: string | Date): string {
        return dayJs.utc(dateString).format('YYYY-MM-DD');
    },
    dayAndDate(dateString: string): string {
        return dayJs.utc(dateString).format('dddd D MMMM');
    },
    relative(dateString: string | Date, compareDateString?: string | Date): string {
        return dayJs(dateString).calendar(compareDateString, {
            sameDay: '[idag]',
            nextDay: '[imorgon]',
            lastDay: '[igår]',
            nextWeek: 'dddd D MMMM',
            lastWeek: 'dddd D MMMM',
            sameElse: 'dddd D MMMM'
        });
    },
    relativeWithTime(dateString: string | Date, compareDateString?: string | Date): string {
        return dayJs(dateString).calendar(compareDateString, {
            sameDay: '[idag] kl. HH.mm',
            nextDay: '[imorgon] kl. HH.mm',
            lastDay: '[igår] kl. HH.mm',
            nextWeek: 'D MMMM kl. HH.mm',
            lastWeek: 'D MMMM kl. HH.mm',
            sameElse: 'D MMMM kl. HH.mm'
        });
    },
    calendar(dateString: string, compareDateString?: string): string {
        return dayJs(dateString).calendar(compareDateString, {
            sameDay: '[idag] kl. HH.mm',
            nextDay: '[imorgon] kl. HH.mm',
            lastDay: '[igår] kl. HH.mm',
            nextWeek: '[på] dddd kl. HH.mm',
            lastWeek: '[i] dddd[s] kl. HH.mm',
            sameElse: 'DD MMM YYYY'
        });
    },
    shortTime(dateString: string | Date): string {
        return dayJs(dateString).format('HH.mm');
    },
    parseDate(dateString: string): Date {
        return dayJs(dateString).toDate();
    },
    diffDays(dateString: string | Date, now?: string | Date): number {
        return dayJs(now).diff(dateString, 'day');
    }
};
