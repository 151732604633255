import httpClient, { IHttpResponse } from '@/common/http-client';
import queryStringBuilder from '@/common/queryStringBuilder';

export default {
    get(params: ScheduleRequest): Promise<IHttpResponse<ScheduleResponse>> {
        const url = '/channelschedule';
        const ids = params.ids ? queryStringBuilder.build(params.ids, 'ids') : undefined;
        return httpClient.get<ScheduleResponse>(url, ids);
    },
    getWithTracks(params: ScheduleWithTracksRequest): Promise<IHttpResponse<Schedule>> {
        const url = '/channelschedulewithtracks';
        return httpClient.get<Schedule>(url, params);
    }
};

interface ScheduleRequest {
    ids?: number[];
}

interface ScheduleWithTracksRequest {
    id: number;
}

export interface ScheduleResponse {
    schedules: Schedule[];
}

export interface Schedule {
    channelId: number;
    scheduleItems: ScheduleItem[];
    track?: Track;
}

export interface ScheduleItem {
    title: string;
    startTimeFormatted: string;
    endTimeFormatted: string;
    start: string;
    end: string;
    programImageUrl: string;
    programImageAltText: string;
}

export interface Track {
    title: string;
    start: string;
    end: string;
}
