












































    import { Mixins, Component, Prop, Watch } from 'vue-property-decorator';

    import Tooltip from '@/components/player/progress-bar/Tooltip.vue';
    import PlayerLiveMarker from '@/components/player/PlayerLiveMarker.vue';
    import Handle from '@/components/player/progress-bar/Handle.vue';
    import Progress from '@/components/player/progress-bar/Progress.vue';
    import ProgressLoadingAnimation from '@/components/player/progress-bar/ProgressLoadingAnimation.vue';
    import Scale from '@/components/animations/Scale.vue';

    import PlaybackMixin from '@/components/mixins/PlaybackMixin';
    import MetadataMixin from '@/components/mixins/MetadataMixin';
    import PlayerStateMixin from '@/components/mixins/PlayerStateMixin';
    import PlayerProgressMixin from '@/components/mixins/PlayerProgressMixin';

    import { themeHelper } from '@/common/theme-helper';
    import dateFormatter from '@/common/date-formatter';

    @Component({
        components: {
            Tooltip,
            PlayerLiveMarker,
            Handle,
            Progress,
            Scale,
            ProgressLoadingAnimation
        }
    })
    export default class ProgressBarLive extends Mixins(PlaybackMixin, MetadataMixin, PlayerStateMixin, PlayerProgressMixin) {
        @Prop(Boolean) alwaysShowHandle: boolean | undefined;

        isHandleActive = false;
        isSeeking = false;
        private seekPosition = 0;

        get showProgressLoadingAnimation(): boolean {
            return this.isLoading || this.isMetadataLoading;
        }

        get activeHandlePositionPercentage(): number {
            const handlePosition = (this.seekPosition / this.liveDuration) * 100;

            if (handlePosition > this.livePositionPercentage) {
                return this.livePositionPercentage;
            }
            if (handlePosition < 0) {
                return 0;
            }
            return handlePosition;
        }

        get handlePositionPercentage(): number {
            if (this.isHandleActive || this.isSeeking) {
                return this.activeHandlePositionPercentage;
            }

            return this.catchupProgressPercentage;
        }

        get showLiveMarker(): boolean {
            if (!this.currentAudio?.endTime || !this.livePositionTime || this.isFatal) {
                return false;
            }

            return this.isBroadcastCatchup && !this.isHandleActive && this.currentAudio.endTime > this.livePositionTime;
        }

        get livePositionPercentage(): number {
            const livePositionPercentage = (this.livePosition / this.liveDuration) * 100;
            return livePositionPercentage <= 100 ? livePositionPercentage : 100;
        }

        get livePosition(): number {
            if (!this.currentAudio?.startTime || !this.livePositionTime) {
                return 0;
            }
            return (this.livePositionTime.getTime() - this.currentAudio.startTime.getTime()) / 1000;
        }

        get liveDuration(): number {
            if (!this.currentAudio?.endTime || !this.currentAudio?.startTime) {
                return 0;
            }

            return (this.currentAudio.endTime.getTime() - this.currentAudio.startTime.getTime()) / 1000;
        }

        get seekTimeFormatted(): string {
            if (!this.currentAudio || !this.currentAudio.startTime) {
                return '';
            }

            const startTime = this.currentAudio?.startTime.getTime();
            const activeTime = (this.liveDuration * this.activeHandlePositionPercentage) / 100;
            const seekTime = startTime + activeTime * 1000;

            if (!seekTime) {
                return '';
            }

            return dateFormatter.shortTime(new Date(seekTime));
        }

        get brandingBackgroundColor(): string {
            return themeHelper.getBgColorClass(this.currentAudio.branding);
        }

        get catchUpDistanceCss(): string {
            return `width: ${this.livePositionPercentage}%`;
        }

        onTouchClick(event: TouchEvent): void {
            if (this.isIcecastLiveMode || !this.isHlsLoaded) {
                return;
            }

            this.setSeekPosition(event.touches[0].clientX);
            this.isHandleActive = true;
        }

        onMouseClick(event: MouseEvent): void {
            if (this.isIcecastLiveMode || !this.isHlsLoaded) {
                return;
            }

            this.setSeekPosition(event.pageX);
            this.isHandleActive = true;
        }

        onTouchScrubbing(event: TouchEvent): void {
            if (this.isSeeking || this.isIcecastLiveMode || !this.isHlsLoaded) {
                return;
            }
            this.setSeekPosition(event.touches[0].clientX);
            this.isHandleActive = true;
        }

        onMouseScrubbing(event: MouseEvent): void {
            if (this.isIcecastLiveMode) {
                return;
            }
            if (event.buttons === 0) {
                this.isHandleActive = false;
            }
            if (this.isSeeking) {
                return;
            }
            this.setSeekPosition(event.pageX);
            if (event.buttons > 0) {
                this.isHandleActive = true;
            }
        }

        onScrubbingEnd(): void {
            if (!this.isHandleActive || this.isIcecastLiveMode || !this.isHlsLoaded) {
                return;
            }

            this.isHandleActive = false;
            this.isSeeking = true;

            this.seekPercentage(this.activeHandlePositionPercentage);
        }

        setSeekPosition(xValue: number): void {
            const el = this.$refs.progressBar as HTMLElement;
            if (!el) {
                return;
            }

            let seekPosition = ((xValue - el.getBoundingClientRect().left) / el.offsetWidth) * this.liveDuration;

            if (seekPosition < 0) {
                seekPosition = 0;
            }
            if (seekPosition > this.liveDuration) {
                seekPosition = this.liveDuration;
            }

            this.seekPosition = seekPosition;
        }

        @Watch('livePosition')
        onLivePositionChange(): void {
            if (this.isSeeking) {
                this.isSeeking = false;
            }
        }
    }
