import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { MenuClickedEvent } from '@/common/custom-events/menu-clicked-event';
import { MenuClickedTrackingData, MenuClickedTrackingPayload } from './gtm-events';

export class TrackMenuClickedEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleFn = (e: MenuClickedEvent): void => this.handle(e);

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(MenuClickedEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(MenuClickedEvent.EventName, this.handleFn);
    }

    handle(event: MenuClickedEvent): void {
        const payload = event.detail;

        if (!payload) {
            return;
        }

        const eventMetadata: MenuClickedTrackingPayload = {
            pageType: this.pageMetadataService.pageView?.pageType,
            menu: payload.linkTitle
        };

        const gtmEvent = new MenuClickedTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
