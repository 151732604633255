import { customEventInit } from '@/common/custom-events/custom-events-common';

export type ChangeChannelEventSource = 'global-meny' | 'karusell' | 'senaste-nyheterna';

type ChangeLocalChannelEventPayload = { eventSource: ChangeChannelEventSource; redirect: boolean };

export class ChangeLocalChannelEvent extends CustomEvent<ChangeLocalChannelEventPayload> {
    public static EventName = 'change-local-channel' as const;

    constructor(eventSource: ChangeChannelEventSource, redirect: boolean = false) {
        super(
            ChangeLocalChannelEvent.EventName,
            customEventInit({
                eventSource: eventSource,
                redirect: redirect
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [ChangeLocalChannelEvent.EventName]: ChangeLocalChannelEvent;
    }
}
