import httpClient from '@/common/http-client';

export default {
    namespaced: true,
    state: {
        _gallery: {
            videos: [],
            images: []
        },
        _publication: null,
        _isModalVisible: false
    },
    getters: {
        images: state => state._gallery.images,
        videos: state => state._gallery.videos,
        total: state => state._gallery.images.length + state._gallery.videos.length,
        isModalVisible: state => state._isModalVisible
    },
    actions: {
        set(context, publication) {
            context.commit('set', publication);
            httpClient.get('/slideshow/items',
                {
                    id: context.state._publication.id,
                    type: context.state._publication.type

                }).then(result => {
                    if (result.data.videos) {
                        context.commit('setVideos', result.data.videos);
                    }
                    if (result.data.images) {
                        context.commit('setImages', result.data.images);
                    }
                }).catch(error => {
                    context.dispatch('clearItems');
                });
        },
        show(context) {
            context.commit('setIsModalVisible', true);
        },
        close(context) {
            context.commit('setIsModalVisible', false);
        },
        onDestroy(context) {
            context.dispatch('clearItems');
            context.commit('set', null);
        },
        clearItems(context) {
            context.commit('setVideos', []);
            context.commit('setImages', []);
        }
    },
    mutations: {
        set(state, publication) {
            state._publication = publication;
        },
        setIsModalVisible(state, data) {
            state._isModalVisible = data;
        },
        setVideos(state, data) {
            state._gallery.videos = data;
        },
        setImages(state, data) {
            state._gallery.images = data;
        }
    }
}
