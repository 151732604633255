import storage from '@/common/storage';
import { AudioEvents } from '@/enums';
import { AudioActions } from '@/store/audio/AudioActions';
import { TrackingState } from '@/store/audio/TrackingState';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { MetadataActions } from '@/store/metadata/MetadataActions';
import { MetadataGetters } from '@/store/metadata/MetadataGetters';
import { Dispatch } from 'vuex';

export class PlaybackEventListener implements EventListenerObject, DisposableEventListener {
    private dispatch: Dispatch;
    private audio: HTMLAudioElement;
    private startTime?: number;
    private rootGetters: any;

    constructor(dispatch: Dispatch, rootGetters: any, audio: HTMLAudioElement, startTime?: number) {
        this.dispatch = dispatch;
        this.audio = audio;
        this.rootGetters = rootGetters;
        this.startTime = startTime;
        this.audio.addEventListener(AudioEvents.Ended, this);
        this.audio.addEventListener(AudioEvents.CanPlay, this);
    }

    dispose(): void {
        this.audio.removeEventListener(AudioEvents.Ended, this);
        this.audio.removeEventListener(AudioEvents.CanPlay, this);
    }

    public async handleEvent(event: Event): Promise<void> {
        if (event.type === AudioEvents.CanPlay) {
            this.onCanPlay();
        }

        if (event.type === AudioEvents.Ended) {
            await this.onAudioEnded();
        }
    }

    private onCanPlay(): void {
        if (this.startTime) {
            this.audio.currentTime = this.startTime;
        }

        this.audio.removeEventListener(AudioEvents.CanPlay, this);
    }

    private async onAudioEnded(): Promise<void> {
        if (this.rootGetters[MetadataGetters.CurrentQueuePosition] >= this.rootGetters[MetadataGetters.QueueLength] - 1) {
            await this.resetAudioPosition();
        } else {
            await this.startNextAudio();
        }
    }

    private async startNextAudio(): Promise<void> {
        const nextAudioIndex = this.rootGetters[MetadataGetters.CurrentQueuePosition] + 1;
        await this.dispatch(MetadataActions.SetCurrentIndex, nextAudioIndex, { root: true });
        await this.dispatch(MetadataActions.FetchCurrentAndAdjacentMetadata, null, { root: true });
        const audio = this.rootGetters[MetadataGetters.CurrentAudio];

        if (!audio) {
            return;
        }

        const trackingState: TrackingState = {
            playlistTitle: this.rootGetters[MetadataGetters.PlaylistTitle],
            startedFromAutoplay: !storage.disableAutoplay
        };

        await this.dispatch(AudioActions.Set, { audio, trackingState }, { root: true });

        if (!storage.disableAutoplay) {
            await this.dispatch(AudioActions.Play, null, { root: true });
        }
    }

    private async resetAudioPosition(): Promise<void> {
        await this.dispatch(AudioActions.Pause, null, { root: true });
        await this.dispatch(AudioActions.ResetPosition, null, { root: true });
    }
}
