import { TrackingMetadata } from '@/tracking/track-types';
import { customEventInit } from './custom-events-common';

type CategoryTagClickedEventPayload = {
    trackingMetadata: TrackingMetadata;
};

export class CategoryTagClickedEvent extends CustomEvent<CategoryTagClickedEventPayload> {
    public static EventName = 'category-tag-clicked' as const;
    constructor(trackingMetadata: TrackingMetadata) {
        super(
            CategoryTagClickedEvent.EventName,
            customEventInit({
                trackingMetadata: trackingMetadata
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [CategoryTagClickedEvent.EventName]: CategoryTagClickedEvent;
    }
}
