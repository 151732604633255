






    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { themeHelper } from '@/common/theme-helper';

    @Component
    export default class ToolTip extends Vue {
        @Prop() active?: boolean;
        @Prop() branding?: string;
        @Prop() position!: number;

        get brandingBackgroundColor(): string {
            return themeHelper.getBgColorClass(this.branding);
        }

        get positionCss(): string {
            return `left: ${this.position}%`;
        }
    }
