import { RootState } from '@/store/RootState';
import { Module } from 'vuex';

export default {
    namespaced: true,
    state: {
        _isInitialized: false,
        _timeEpoch: 0,
        _isEmbed: false,
        _clientWidth: window.innerWidth
    },
    mutations: {
        setIsInitialized: (state: CommonState, isInitialized: boolean): boolean => (state._isInitialized = isInitialized),
        updateTimeEpoch: (state: CommonState): number => (state._timeEpoch = new Date().getTime()),
        setIsEmbed: (state: CommonState): boolean => (state._isEmbed = true),
        updateClientWidth: (state: CommonState): number => (state._clientWidth = window.innerWidth)
    }
} as Module<CommonState, RootState>;

export interface CommonState {
    _isInitialized: boolean;
    _timeEpoch: number;
    _isEmbed: boolean;
    _clientWidth: number;
}
