import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { PageMetadataService } from '@/common/page-metadata-service';
import { MenuOpenEvent } from '@/common/custom-events/menu-open-event';
import { MenuOpenTrackingData, MenuOpenTrackingPayload } from './gtm-events';

export class TrackMenuOpenEventListener implements DisposableEventListener {
    private pageMetadataService: PageMetadataService;
    private handleFn = (): void => this.handle();

    constructor(pageMetadataService: PageMetadataService) {
        this.pageMetadataService = pageMetadataService;
        window.addEventListener(MenuOpenEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(MenuOpenEvent.EventName, this.handleFn);
    }

    handle(): void {
        const eventMetadata: MenuOpenTrackingPayload = {
            pageType: this.pageMetadataService.pageView?.pageType
        };

        const gtmEvent = new MenuOpenTrackingData(eventMetadata);

        googleTagManager.track(gtmEvent);
    }
}
