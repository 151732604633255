import ListenedTimeHelper from '@/common/ListenedTimeHelper';
import { AudioMetadata } from '@/components/models/';
import { AudioEvents } from '@/enums';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import { reportListenDuration } from '@/tracking/ListenDurationTracker';

enum EventType {
    Start = 'start',
    Stop = 'stop',
    SeekingStart = 'start_after_seeking'
}

export class ListenDurationTrackerEventListener implements DisposableEventListener, EventListenerObject {
    private playing: boolean = false;
    private hasStarted: boolean = false;
    private audioElement: HTMLAudioElement;
    private audioMetadata: AudioMetadata;
    private isEmbed: boolean;
    private currentStart: number = 0;
    private listenedTimeHelper: ListenedTimeHelper;
    private hasTracked5Seconds: boolean = false;
    private readonly events: AudioEvents[] = [
        AudioEvents.TimeUpdate,
        AudioEvents.Seeking,
        AudioEvents.Ended,
        AudioEvents.Pause,
        AudioEvents.Play,
        AudioEvents.Playing,
        AudioEvents.LoadStart
    ];

    constructor(audioElement: HTMLAudioElement, audioMetadata: AudioMetadata, isEmbed: boolean) {
        this.audioElement = audioElement;
        this.audioMetadata = audioMetadata;
        this.isEmbed = isEmbed;
        this.listenedTimeHelper = new ListenedTimeHelper();
        this.events.map(e => this.audioElement.addEventListener(e, this));
    }

    public handleEvent(event: Event): void {
        switch (event.type) {
            case AudioEvents.Startup:
                this.onStartUp();
                break;
            case AudioEvents.TimeUpdate:
                this.onTimeUpdate();
                break;
            case AudioEvents.Play:
                this.onPlay();
                break;
            case AudioEvents.Ended:
            case AudioEvents.Pause:
                this.onPause();
                break;
            case AudioEvents.Seeking:
                this.onSeeking();
                break;
            default:
                break;
        }
    }


    private onStartUp(): void {
        if (!this.hasStarted) {
            this.playing = true;
            this.hasStarted = true;
        }
    }

    public dispose(): void {
        if (this.playing) {
            this.playing = false;
            this.reportDuration(EventType.Stop);
        }
        this.events.map(e => this.audioElement.removeEventListener(e, this));
    }

    private onPlay(): void {
        if (!this.playing) {
            this.playing = true;
            this.currentStart = this.audioElement.currentTime;
            this.reportDuration(EventType.Start);
        }
    }

    private onSeeking(): void {
        if (this.listenedTimeHelper.currentTime > this.currentStart) {
            this.reportDuration(EventType.Stop);
        }

        this.currentStart = this.audioElement.currentTime;
        this.listenedTimeHelper.onSeek(this.audioElement.currentTime);
        this.reportDuration(EventType.SeekingStart);
    }

    private onPause(): void {
        if (this.playing) {
            this.playing = false;
            this.reportDuration(EventType.Stop);
        }
        this.currentStart = this.audioElement.currentTime;
    }

    private onTimeUpdate(): void {
        this.listenedTimeHelper.currentTime = this.audioElement.currentTime;

        if (!this.hasTracked5Seconds && this.listenedTimeHelper.listenedTime > Thresholds.FiveSeconds) {
            this.hasTracked5Seconds = true;
        }
    }

    private reportDuration(eventType: EventType): void {
        if (!this.audioMetadata.id || !this.audioMetadata.statistics.audioId) {
            return;
        }

        const hasDuration = this.listenedTimeHelper.currentTime - this.currentStart >= 0;

        if (!hasDuration && eventType != EventType.Start) {
            return;
        }

        reportListenDuration(
            this.audioMetadata.statistics.audioId,
            this.isEmbed,
            this.currentStart,
            this.listenedTimeHelper.currentTime,
            false,
            eventType
        );
    }
}

enum Thresholds {
    FiveSeconds = 5
}
