import httpClient from '@/common/http-client';
import { SrError } from '@/logging/SrError';
import { AudioType, AudioQuality, AudioFormat } from '@/components/models';
import { PlayerMode } from '@/enums/PlayerMode';
import { AudioResponseWithMetadata } from './responses/AudioResponse';

export default {
    async getMetadata(params: MetadataRequest, isEmbed: boolean, playerMode: PlayerMode): Promise<AudioResponseWithMetadata> {
        try {
            const url = '/playerajax/audio';
            params.quality = params.quality || AudioQuality.High;
            const response = await httpClient.get<AudioResponseWithMetadata>(url, params);

            return response.data;
        } catch (error) {
            if (httpClient.isAxiosError(error)) {
                const req = error.request;
                const status = req?.status;
                const url = req?.responseURL;
                const errorMessage =
                    status === 404 ? 'Failing to fetch ondemand metadata (404)' : 'Failing to fetch ondemand metadata';

                throw new SrError({
                    message: errorMessage,
                    url: url,
                    error: error,
                    application: isEmbed ? 'embedspelare' : 'webbradio',
                    data: {
                        status: status,
                        metadataRequest: params,
                        playerMode: playerMode
                    }
                });
            }

            throw error;
        }
    }
};

export type MetadataRequest = {
    id: number;
    type: AudioType;
    publicationId?: number;
    quality?: AudioQuality;
    format?: AudioFormat;
};
