import { Vue, Component } from 'vue-property-decorator';
import siteSettings from '@/common/site-settings';

@Component
export default class SvgMixin extends Vue {
    protected get svgSpriteUrl(): string {
        return siteSettings.svgSpriteName;
    }

    $store: any;
}
