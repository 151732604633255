export default class ListenedTimeHelper {
    private _storedListenedTime: number = 0;
    private _seekedStartPosition: number = 0;
    public currentTime: number = 0;

    public get listenedTime(): number {
        return this._storedListenedTime + this.currentTime - this._seekedStartPosition;
    }

    public onSeek(newPosition: number): void {
        this._storedListenedTime += this.currentTime - this._seekedStartPosition;
        this._seekedStartPosition = newPosition;
        this.currentTime = newPosition;
    }
}
