export function zeroPad(number: number): string | number {
    if (number < 10) {
        return '0' + number;
    }

    return number;
}

export function computeStartAndStopTime(starttime: any, endtime?: any): string {
    if (!starttime || !endtime) {
        return '';
    }

    const startTime = new Date(starttime),
        endTime = new Date(endtime),
        startTimeHours = zeroPad(startTime.getHours()),
        endTimeHours = zeroPad(endTime.getHours()),
        startTimeMinutes = zeroPad(startTime.getMinutes()),
        endTimeMinutes = zeroPad(endTime.getMinutes());

    return 'kl. ' + startTimeHours + '.' + startTimeMinutes + ' - ' + endTimeHours + '.' + endTimeMinutes;
}

export function computeDateString(date: Date): string {
    const normalizedDate = new Date(date);
    normalizedDate.setMilliseconds(0);
    return normalizedDate.toJSON();
}

export function secondsToMinutes(totalSeconds: number): string {
    if (!totalSeconds) {
        return '';
    }

    const minutesTotal = totalSeconds / 60,
        minutes = minutesTotal % 60,
        seconds = Math.ceil(totalSeconds % 60);

    if (minutesTotal > 10) {
        return Math.round(minutesTotal) + ' min';
    }

    return Math.floor(minutes) + ':' + zeroPad(seconds) + ' min';
}

export function secondsToTime(secNum: number): string {
    const negative = secNum < 0;
    secNum = Math.abs(secNum);

    const hours = Math.floor(secNum / 3600);
    let minutes: string | number = Math.floor((secNum - hours * 3600) / 60);
    let seconds: string | number = Math.floor(secNum - hours * 3600 - minutes * 60);

    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    const time = (hours > 0 ? hours + ':' : '') + minutes + ':' + seconds;
    return (negative ? '-' : '') + time;
}
