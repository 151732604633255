import { TrackingMetadata } from '@/tracking/track-types';
import { customEventInit } from './custom-events-common';

interface ShareEventPayload {
    trackingMetadata: TrackingMetadata;
}

export class ShareEvent extends CustomEvent<ShareEventPayload> {
    public static EventName = 'share' as const;

    constructor(trackingMetadata: TrackingMetadata) {
        super(
            ShareEvent.EventName,
            customEventInit({
                trackingMetadata: trackingMetadata
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [ShareEvent.EventName]: ShareEvent;
    }
}
