




    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { themeHelper } from '@/common/theme-helper';
    import { Branding } from '@/components/models/Branding';

    @Component
    export default class Progress extends Vue {
        @Prop(Number) progress!: number;
        @Prop({ type: String as () => Branding }) branding?: Branding;
        @Prop(Boolean) rounded?: boolean;

        private get brandingBackgroundColor(): string {
            return themeHelper.getBgColorClass(this.branding);
        }

        private get css(): string {
            return `width: ${this.progress}%`;
        }
    }
