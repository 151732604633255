import { consentService } from '@/common/consent-service';
import httpClient from '@/common/http-client';
import siteSettings from '@/common/site-settings';
import { AxiosError } from 'axios';

function trimUrl(trackUrl: string, statisticsEventApiUrl: string): string {
    const url = statisticsEventApiUrl.endsWith('/') && trackUrl.startsWith('/') ? trackUrl.substring(1) : trackUrl;
    return statisticsEventApiUrl + url;
}

function getCurrentPath(): string | null {
    if (window) {
        return window.location.host + location.pathname;
    }
    return null;
}

export function reportListenDuration(cId: string, duration: number, eventType: string, streamId: string, timePoint: Date): void {
    if (!consentService.isStatisticsAllowed()) {
        return;
    }

    const statisticsEventApiUrl = siteSettings.statisticsEventApiUrl;
    if (!statisticsEventApiUrl) {
        return;
    }

    const params = [];
    params.push('cid=' + cId);
    params.push('duration=' + duration);
    params.push('eventtype=' + eventType);
    params.push('platform=' + 'site');
    params.push('streamid=' + streamId);
    params.push('timepoint=' + timePoint.toISOString());
    params.push('referrer=' + getCurrentPath());

    const trackUrl = '/v1/stream?' + params.join('&');
    const url = trimUrl(trackUrl, statisticsEventApiUrl);
    httpClient
        .get(url)
        .then(() => {
            return;
        })
        .catch((error: AxiosError) => {
            if (error.code === '400') {
                return;
            }
            throw error;
        });
}
