






    import { Vue, Component, Prop } from 'vue-property-decorator';
    import LazyImage from '@/components/shared/LazyImage.vue';

    @Component({
        components: {
            LazyImage
        }
    })
    export default class Thumbnail extends Vue {
        @Prop(Boolean) small?: boolean;
        @Prop(Boolean) extraLarge?: boolean;
        @Prop(String) url!: string;

        onClick(): void {
            this.$emit('click');
        }
    }
