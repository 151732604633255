import storage from '@/common/storage';
import playerMessages from '@/enums/player-messages';
import playerStates from '@/enums/player-states';
import { playerLogger } from '@/logging/PlayerErrorLogger';
import playerApi from '@/api/player-api';
import { logger } from '@/api/log-api';
import { checkGeoblock } from '@/store/check-geoblock';
import { env } from '@/common/env';
import { PlayerTrackingAction } from '@/tracking/track-types';
import { gtmPlayerTracker } from '@/tracking/gtm/gtm-player-tracker';

const AUDIO_AVAILABLE = 0;
const AUDIO_EXPIRED = 100;
const AUDIO_NOT_FOUND = 101;
const FIVE_SECOND_THRESHOLD = 5;
const TEN_SECOND_THRESHOLD = 10;
const THIRTY_SECOND_THRESHOLD = 30;
const SIXTY_SECOND_THRESHOLD = 60;
const AUDIO_DELAY_SECONDS = 70;

const audioElement = env.isClient() ? new Audio() : null;

let lastTracked = 0;
let hasTracked5Seconds = false;
let hasTracked10Seconds = false;
let hasTracked30Seconds = false;
let hasTracked60Seconds = false;
let accumulatedListenedTime = 0;
let lastTickTime = null;
let completedEpisodes = {};

export default {
    namespaced: true,
    state: {
        isActive: false,
        currentTime: new Date(),
        playerState: playerStates.PAUSED,
        statusMessage: '',
        playerMessage: '',
        audioDelayMilliseconds: AUDIO_DELAY_SECONDS * 1000
    },
    getters: {
        active: state => state.isActive,
        id: function (state, getters, rootState) {
            return rootState.player.live.id;
        },
        experiment: function (state, getters, rootState, rootGetters) {
            return rootGetters['player/live/experiment'];
        },
        type: function (state, getters, rootState) {
            return rootState.player.live.type;
        },
        publicationId: function (state, getters, rootState) {
            return rootState.player.live.publicationId;
        },
        quality: function (state, getters, rootState) {
            return rootState.player.quality;
        },
        trackContext: function (state, getters, rootState) {
            return rootState.player.trackContext;
        },
        isEmbed: function (state) {
            return state.trackContext === 'embed';
        },
        currentEpisode: function (state, getters, rootState, rootGetters) {
            return rootGetters['player/live/currentScheduleItem'];
        },
        channelName: function (state, getters, rootState) {
            return rootState.player.live.channelTheme;
        },
        channelId: function (state, getters, rootState) {
            return rootState.player.live.id;
        },
        eightyPercentOfCurrentEpisodeTime: function (state, getters) {
            if (!getters.currentEpisode) {
                return undefined;
            }

            const startTime = new Date(getters.currentEpisode.starttime);
            const endTime = new Date(getters.currentEpisode.endtime);
            const length = endTime.getTime() - startTime.getTime();
            const eightyPercent = length * 0.8;
            return new Date(startTime.getTime() + eightyPercent);
        }
    },
    actions: {
        init: function (context) {
            context.dispatch('addEventListeners');
        },
        addEventListeners: function (context) {
            audioElement.addEventListener('playing', function () {
                context.dispatch('setPlayingState');
            });
            audioElement.addEventListener('timeupdate', function () {
                context.commit('setCurrentTime', new Date());
                context.dispatch('saveCurrentTimeToStorage');
                const dateNow = new Date();
                setAccumulatedListenedTime(dateNow);
                setLastTickTime(dateNow);
                trackListenedTime(context);
            });
            audioElement.addEventListener('volumechange', function () {
                context.dispatch('player/setVolume', audioElement.volume * 100, { root: true });
            });
            audioElement.addEventListener('stalled', function () {
                context.dispatch('setReconnectingState');
            });
            audioElement.addEventListener('error', function () {
                context.dispatch('handleAudioError');
            });
        },
        async play(context, audio) {
            resetAudioState(context);
            context.commit('setIsActive', true);
            context.dispatch('setLoadingState');

            try {
                context.commit('setPlayerMessage');
                const response = await loadAudio(context, audio);

                if (response.data.state === AUDIO_AVAILABLE) {
                    setAudioElementSrcAndFormat(response.data.audioUrl);
                    audioElement.play();

                    if (response.data.isGeoblockEnabled) {
                        await checkGeoblock(response.data.audioUrl, context);
                    }
                } else if (response.data.state === AUDIO_EXPIRED) {
                    context.dispatch('setErrorState', playerMessages.AUDIO_EXPIRED);
                } else if (response.data.state === AUDIO_NOT_FOUND) {
                    context.dispatch('setErrorState', playerMessages.AUDIO_NOT_FOUND);
                }
            } catch (error) {
                setAudioElementSrcAndFormat(null);
                audioElement.pause();
                context.dispatch('handleUrlError', error);
            }
        },
        stop: function (context) {
            if (context.state.playerState === playerStates.PLAYING) {
                trackPlayerListeningEvent(context);
            }
            audioElement.pause();
            context.dispatch('setPausedState');
        },
        resume: function (context) {
            if (context.state.isError || !audioElement.src) {
                context.dispatch('play', { id: context.getters.id, type: context.getters.type });
            } else {
                setLastTickTime(null);
                context.dispatch('setLoadingState');
                audioElement.play();
            }
        },
        restoreState: function (context) {
            context.commit('setIsActive', true);
        },
        handleAudioError: function (context) {
            const errorMessage = getAudioErrorMessage(audioElement.error.code);
            context.dispatch('setErrorState', errorMessage);
            const errorCode = audioElement.error ? audioElement.error.code : '';
            playerLogger.logIcecastError(audioElement.src, `errorCode: ${errorCode}`);
        },
        handleUrlError: function (context, error) {
            const statusCode = error.request ? error.request.status : 0;
            const url = error.request ? error.request.responseURL : '';
            const errorMessage = getUrlErrorMessage(statusCode);
            context.dispatch('setErrorState', errorMessage);
            if (statusCode !== 404) {
                playerLogger.logIcecastError(url || audioElement.src, `http status code: ${statusCode}`);
            }
        },
        setPlayingState: function (context) {
            context.commit('setPlayerState', playerStates.PLAYING);
            context.commit('setStatusMessage');
        },
        setPausedState: function (context) {
            context.commit('setPlayerState', playerStates.PAUSED);
            context.commit('setStatusMessage');
        },
        setLoadingState: function (context) {
            context.commit('setPlayerState', playerStates.LOADING);
            context.commit('setStatusMessage', playerMessages.BUFFERING);
        },
        setErrorState: function (context, errorMessage) {
            context.commit('setPlayerState', playerStates.ERROR);
            context.commit('setStatusMessage', errorMessage);
        },
        setReconnectingState: function (context) {
            context.commit('setPlayerState', playerStates.RECONNECTING);
            context.commit('setStatusMessage', playerMessages.RECONNECTING);
        },
        deactivate: function (context) {
            context.dispatch('stop');
            context.commit('setIsActive', false);
        },
        unload: function (context) {
            if (context.state.playerState === playerStates.PLAYING) {
                trackPlayerListeningEvent(context);
                return;
            }
        },
        setVolume: function (context, volume) {
            audioElement.volume = volume / 100;
        },
        onQualityChange: function (context) {
            if (!context.state.isActive) {
                return;
            }
            context.dispatch('play', { id: context.getters.id, type: context.getters.type });
        },
        saveCurrentTimeToStorage: function () {
            storage.lastTracked = lastTracked;
            storage.accumulatedListenedTime = accumulatedListenedTime;
        },
        trackPlay: function (context) {
            gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.PLAY);
        }
    },
    mutations: {
        setIsActive: function (state, isActive) {
            state.isActive = isActive;
        },
        setCurrentTime: function (state, currentTime) {
            state.currentTime = currentTime;
        },
        setPlayerState: function (state, playerState) {
            state.playerState = playerState;
        },
        setStatusMessage: function (state, statusMessage) {
            state.statusMessage = statusMessage || '';
        },
        setPlayerMessage: function (state, playerMessage) {
            state.playerMessage = playerMessage || '';
        }
    }
};

function loadAudio(context, audio) {
    audioElement.load();
    const options = {
        id: audio.id,
        type: audio.type,
        quality: context.getters.quality,
        format: 'iis'
    };

    return playerApi.getAudioUrl(options);
}

function setAudioElementSrcAndFormat(src) {
    if (src) {
        audioElement.src = src;
    } else {
        audioElement.removeAttribute('src');
    }
}

function getAudioErrorMessage(errorCode) {
    return playerMessages.PLAYBACK_ERROR + ' (FELKOD: ' + errorCode + ')';
}

function setLastTickTime(dateNow) {
    lastTickTime = dateNow;
}

function setAccumulatedListenedTime(dateNow) {
    if (lastTickTime) {
        accumulatedListenedTime = (dateNow.getTime() - lastTickTime.getTime()) / 1000 + accumulatedListenedTime;
    }
}

function trackListenedTime(context) {
    const threshold = getNextGaThreshold();

    if (!hasTracked5Seconds && accumulatedListenedTime > FIVE_SECOND_THRESHOLD) {
        gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.LISTENED_5_SEC);
        hasTracked5Seconds = true;
    }

    if (!hasTracked10Seconds && accumulatedListenedTime > TEN_SECOND_THRESHOLD) {
        gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.LISTENED_10_SEC);
        hasTracked10Seconds = true;
    }
    if (!hasTracked30Seconds && accumulatedListenedTime > THIRTY_SECOND_THRESHOLD) {
        gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.LISTENED_30_SEC);
        hasTracked30Seconds = true;
    }
    if (!hasTracked60Seconds && accumulatedListenedTime > SIXTY_SECOND_THRESHOLD) {
        gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.LISTENED_60_SEC);
        hasTracked60Seconds = true;
    }

    if (accumulatedListenedTime > threshold && lastTracked < threshold) {
        trackPlayerListeningEvent(context);
    }

    if (!completedEpisodes[context.getters.currentEpisode.starttime]) {
        const completedEpisodeMark = context.getters.eightyPercentOfCurrentEpisodeTime;
        const completedEpisodeThreshold = new Date(completedEpisodeMark.getTime() + 5 * 1000);
        const adjustedStreamTime = new Date(context.state.currentTime.getTime() - context.state.audioDelayMilliseconds);
        const hasCompleted = adjustedStreamTime > completedEpisodeMark && adjustedStreamTime < completedEpisodeThreshold;

        if (hasCompleted) {
            completedEpisodes[context.getters.currentEpisode.starttime] = true;
        }
    }
}

function trackPlayerListeningEvent(context) {
    const trackValue = Math.round(accumulatedListenedTime - lastTracked);

    const oneWeek = 604800;

    if (trackValue < 1) {
        return;
    }

    if (trackValue > oneWeek) {
        logger.logDebug({
            message: 'Heartbeat too large, icecast',
            source: 'icecast-store.js',
            url: audioElement.src,
            data: {
                trackValue: trackValue,
                lastTracked: lastTracked,
                audioPosition: storage.audioPosition,
                playerLastTracked: storage.lastTracked,
                accumulatedListenedTime: storage.accumulatedListenedTime,
                audioElCurrentTime: audioElement.currentTime,
                audioElSrc: audioElement.src
            }
        });

        lastTracked = accumulatedListenedTime;
        return;
    }

    gtmPlayerTracker.trackLiveListening(context, PlayerTrackingAction.LISTENING, trackValue);
    lastTracked = accumulatedListenedTime;
}

function getUrlErrorMessage(httpStatusCode) {
    if (httpStatusCode === 404) {
        return playerMessages.AUDIO_NOT_FOUND;
    }
    return playerMessages.AUDIO_INTERNAL_ERROR;
}

function getNextGaThreshold() {
    if (lastTracked < 300) {
        return Math.floor(lastTracked / 60) * 60 + 60;
    }

    return lastTracked + 300;
}

function resetAudioState(context) {
    lastTracked = 0;
    hasTracked5Seconds = false;
    //Behövas 10,30 nollställas?
    hasTracked10Seconds = false;
    hasTracked30Seconds = false;
    accumulatedListenedTime = 0;
    context.dispatch('saveCurrentTimeToStorage', 0);
    lastTickTime = null;
    completedEpisodes = {};
}
