export enum Branding {
    P1 = 'p1',
    P2 = 'p2',
    P3 = 'p3',
    P4 = 'p4',
    Barnradion = 'junior-web',
    Kultur = 'red',
    Vetenskap = 'green',
    Radiosporten = 'sportgreen',
    Ekot = 'ekot',
    Same = 'same',
    Finska = 'sisu',
    Default = 'default'
}
