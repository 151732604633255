import { TrackingMetadata } from '@/tracking/track-types';
import { customEventInit } from './custom-events-common';

interface DownloadEpisodeClickedEventPayload {
    trackingMetadata: TrackingMetadata;
}

export class DownloadEpisodeClickedEvent extends CustomEvent<DownloadEpisodeClickedEventPayload> {
    public static EventName = 'download-episode-clicked' as const;

    constructor(trackingMetadata: TrackingMetadata) {
        super(
            DownloadEpisodeClickedEvent.EventName,
            customEventInit({
                trackingMetadata: trackingMetadata
            })
        );
    }
}

declare global {
    interface WindowEventMap {
        [DownloadEpisodeClickedEvent.EventName]: DownloadEpisodeClickedEvent;
    }
}
