import { googleTagManager } from '@/tracking/gtm/google-tag-manager';
import { DisposableEventListener } from '@/store/interfaces/disposable-event-listener';
import {
    PlayerAutoPlayToggleTrackingData,
    PlayerExpandedTrackingData,
    PlayerFastforwardTrackingData,
    PlayerNextAudioTrackingData,
    PlayerPreviousAudioTrackingData,
    PlayerRewindTrackingData,
    PlayerSeekToLiveTrackingData,
    PlayerSeekTrackingData,
    PlayerTrackingPayload
} from './gtm-player-events';
import { gtmPlayerTracker } from './gtm-player-tracker';
import { PlayerFastforwardEvent } from '@/common/custom-events/player-interaction/player-fastforward-event';
import { PlayerInteractionPayload } from '@/common/custom-events/player-interaction/player-interaction-payload';
import { PlayerRewindEvent } from '@/common/custom-events/player-interaction/player-rewind-event';
import { GtmTrackingData } from './gtm-events';
import { PlayerExpandedEvent } from '@/common/custom-events/player-interaction/player-expanded-event';
import { PlayerPreviousAudioEvent } from '@/common/custom-events/player-interaction/player-previous-audio-event';
import { PlayerNextAudioEvent } from '@/common/custom-events/player-interaction/player-next-audio-event';
import { PlayerSeekEvent } from '@/common/custom-events/player-interaction/player-seek-event';
import { PlayerSeekToLiveEvent } from '@/common/custom-events/player-interaction/player-seek-to-live-event';
import { PlayerAutoPlayToggleEvent } from '@/common/custom-events/player-interaction/player-autoplay-toggle-event';

export type PlayerInteractionEvent =
    | PlayerExpandedEvent
    | PlayerRewindEvent
    | PlayerFastforwardEvent
    | PlayerPreviousAudioEvent
    | PlayerNextAudioEvent
    | PlayerSeekEvent
    | PlayerSeekToLiveEvent
    | PlayerAutoPlayToggleEvent;

export class TrackPlayerInteractionEventListener implements DisposableEventListener {
    private handleFn = (e: PlayerInteractionEvent): void => this.handle(e);

    constructor() {
        window.addEventListener(PlayerExpandedEvent.EventName, this.handleFn);
        window.addEventListener(PlayerRewindEvent.EventName, this.handleFn);
        window.addEventListener(PlayerFastforwardEvent.EventName, this.handleFn);
        window.addEventListener(PlayerPreviousAudioEvent.EventName, this.handleFn);
        window.addEventListener(PlayerNextAudioEvent.EventName, this.handleFn);
        window.addEventListener(PlayerSeekEvent.EventName, this.handleFn);
        window.addEventListener(PlayerSeekToLiveEvent.EventName, this.handleFn);
        window.addEventListener(PlayerAutoPlayToggleEvent.EventName, this.handleFn);
    }

    dispose(): void {
        window.removeEventListener(PlayerExpandedEvent.EventName, this.handleFn);
        window.removeEventListener(PlayerRewindEvent.EventName, this.handleFn);
        window.removeEventListener(PlayerFastforwardEvent.EventName, this.handleFn);
        window.removeEventListener(PlayerPreviousAudioEvent.EventName, this.handleFn);
        window.removeEventListener(PlayerNextAudioEvent.EventName, this.handleFn);
        window.removeEventListener(PlayerSeekEvent.EventName, this.handleFn);
        window.removeEventListener(PlayerSeekToLiveEvent.EventName, this.handleFn);
        window.removeEventListener(PlayerAutoPlayToggleEvent.EventName, this.handleFn);
    }

    handle(event: PlayerInteractionEvent): void {
        const payload: PlayerTrackingPayload = this.createGtmPayload(event.detail);
        let gtmTrackingData: GtmTrackingData<PlayerTrackingPayload> | null = null;

        if (event instanceof PlayerFastforwardEvent) {
            gtmTrackingData = new PlayerFastforwardTrackingData(payload);
        } else if (event instanceof PlayerRewindEvent) {
            gtmTrackingData = new PlayerRewindTrackingData(payload);
        } else if (event instanceof PlayerExpandedEvent) {
            gtmTrackingData = new PlayerExpandedTrackingData(payload);
        } else if (event instanceof PlayerPreviousAudioEvent) {
            gtmTrackingData = new PlayerPreviousAudioTrackingData(payload);
        } else if (event instanceof PlayerNextAudioEvent) {
            gtmTrackingData = new PlayerNextAudioTrackingData(payload);
        } else if (event instanceof PlayerSeekEvent) {
            gtmTrackingData = new PlayerSeekTrackingData(payload);
        } else if (event instanceof PlayerSeekToLiveEvent) {
            gtmTrackingData = new PlayerSeekToLiveTrackingData(payload);
        } else if (event instanceof PlayerAutoPlayToggleEvent) {
            gtmTrackingData = new PlayerAutoPlayToggleTrackingData(payload);
        }

        if (gtmTrackingData != null) {
            googleTagManager.track(gtmTrackingData);
        }
    }

    private createGtmPayload(eventPayload: PlayerInteractionPayload): PlayerTrackingPayload {
        return gtmPlayerTracker.createGtmPayload(eventPayload.audioMetadata, eventPayload.playlistTitle);
    }
}
