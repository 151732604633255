import httpClient, { IHttpResponse } from '@/common/http-client';
import { AudioResponse, AudioMetadataResponse } from './responses/AudioResponse';

export default {
    getAudioUrl(params: AudioUrlRequest): Promise<IHttpResponse<AudioResponse>> {
        const url = '/playerajax/getaudiourl';
        params.quality = params.quality || 'medium';
        return httpClient.get<AudioResponse>(url, params);
    },
    getAudioMetadata(params: AudioMetadataRequest): Promise<IHttpResponse<AudioMetadataResponse>> {
        const url = '/playerajax/audiometadata';
        return httpClient.get<AudioMetadataResponse>(url, params);
    }
};

type AudioUrlRequest = {
    id: string;
    type: 'episode' | 'clip' | 'publication' | 'channel' | 'extra' | 'music' | 'secondary';
    quality: 'low' | 'medium' | 'high' | 'auto' | undefined;
    format: 'iis' | 'hls';
};

type AudioMetadataRequest = {
    id: string;
    type: 'episode' | 'clip' | 'publication' | 'channel' | 'extra' | 'music' | 'secondary';
    publicationId: string | undefined;
};
